import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography'
import colors from './colors'

interface FontStyle {
    [key: string]: TypographyStyleOptions
}

const fontStyles: FontStyle = {
    body1: {
        fontFamily: 'Roboto',
        fontSize: '1.063rem',
        fontStyle: 'normal',
        fontWeight: 'normal',
        lineHeight: 1.0,
    },
    body2: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '1.063rem',
    },
    subtitle1: {
        fontFamily: 'Roboto',
        color: colors.main,
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.75rem',
    },
    subtitle2: {
        color: colors.main,
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.80rem',
        // lineHeight: 0.75,
    },
    button: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '0.9375rem',
        textTransform: 'none',
        lineHeight: 1.6,
    },
    FormTextSmall: {
        //h6
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '0.875rem',
        // lineHeight: 1,
    },
    SaveProgressTitle: {
        //h5
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.25rem',
        // lineHeight: 2.125,
    },
    Abschnittssubline: {
        //h4
        fontFamily: 'Roboto',
        color: colors.main,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '17px',
        // lineHeight: 1.625,
    },
    ProduktboxProduktTitel: {
        //h3
        fontFamily: 'Roboto',
        color: colors.main,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.188rem',
        // lineHeight: 1.75,
    },
    UbersichtTitel: {
        //h2
        fontFamily: 'Roboto',
        color: colors.main,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.438rem',
        // lineHeight: 2.125,
    },
    Abschnittsheadline: {
        //h1
        fontFamily: 'Roboto',
        color: colors.main,
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '1.938rem',
    },
}
export default fontStyles
