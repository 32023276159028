import { Box, Checkbox, Collapse, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core'
import ViewWrapper from 'components/ViewWrapper'
import { PostCodeDetail } from 'graphql/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import InputMask from 'react-input-mask'
import { AvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import { ContactDataState } from 'store/ContactData/ContactData.reducer'
import { Error, ViewType } from 'store/GeneralState/GeneralState.reducer'
import { UserInfo, getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import { Customize } from 'utils/customize'
import { salutationIsCompany } from 'utils/testable/functions'
import ContactFields from '../../components/ContactFields'
import TestIDs from '../../utils/TestIDs'
import colors from '../../utils/colors'
import { useContactDataReducer } from './useContactDataReducer'

export interface RenderContactDataProps {
    b2b: boolean
    errors: Error[]
    contactData: ContactDataState
    availability: AvailabilityCheckState
    inLineErrors: string[]
    setContactDataPartial: (payload: Partial<ContactDataState>) => void
    setContactData?: (payload: ContactDataState) => void
    setEditContactAddress?: (
        zip: string,
        city: string,
        street: string,
        houseNumber: string,
        additionalInfo: string,
        delivery: boolean,
    ) => void
    customizeJsData: Customize | undefined
    userInfo: UserInfo | null
}

export const renderContactData = (props: RenderContactDataProps): JSX.Element => {
    const {
        availability,
        b2b,
        contactData,
        customizeJsData,
        errors,
        inLineErrors,
        setContactDataPartial,
        userInfo,
        setContactData,
        setEditContactAddress,
    } = props

    const { t } = useTranslation()

    let invalidBirthday = ''
    if (inLineErrors.includes('birthDate_invalid')) {
        invalidBirthday = 'invalid'
    } else if (inLineErrors.includes('birthDate_min')) {
        invalidBirthday = 'min'
    } else if (inLineErrors.includes('birthDate_max')) {
        invalidBirthday = 'max'
    }

    return (
        <>
            <ContactFields
                testCategory={'PERSONAL'}
                limitedSalutationOptions={true}
                city={availability.selectedCity}
                houseNumber={availability.selectedHouseNumber}
                isAddressDisabled={true}
                lastName={contactData.personalLastName}
                setLastName={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalLastName: v })
                        : setContactDataPartial({ personalLastName: v })
                }
                name={contactData.personalName}
                setName={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalName: v })
                        : setContactDataPartial({ personalName: v })
                }
                salutation={contactData.personalSalutation}
                title={contactData.personalTitle}
                setSalutation={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalSalutation: v })
                        : setContactDataPartial({ personalSalutation: v })
                }
                setTitle={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalTitle: v })
                        : setContactDataPartial({ personalTitle: v })
                }
                street={availability.selectedStreet}
                zip={availability.zip}
                showAdditionalAddressInfo={true}
                additionalInfoAddr={contactData.personalAdditionalAddressInfo}
                setAdditionalInfoAddr={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, personalAdditionalAddressInfo: v })
                        : setContactDataPartial({ personalAdditionalAddressInfo: v })
                }
                company={b2b || salutationIsCompany(contactData.personalSalutation) ? contactData.company : undefined}
                setCompany={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, company: v })
                        : setContactDataPartial({ company: v })
                }
                companyLegalForm={b2b ? contactData.companyLegalForm : undefined}
                setCompanyLegalForm={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, companyLegalForm: v })
                        : setContactDataPartial({ companyLegalForm: v })
                }
                companyLocation={b2b ? contactData.companyLocation : undefined}
                setCompanyLocation={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, companyLocation: v })
                        : setContactDataPartial({ companyLocation: v })
                }
                companyRegisterEntry={b2b ? contactData.companyRegisterEntry : undefined}
                setCompanyRegistrationEntry={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, companyRegisterEntry: v })
                        : setContactDataPartial({ companyRegisterEntry: v })
                }
                companyId={b2b ? contactData.companyId : undefined}
                setCompanyId={(v) =>
                    setContactData
                        ? setContactData({ ...contactData, companyId: v })
                        : setContactDataPartial({ companyId: v })
                }
                contactDataViewConfiguration={
                    customizeJsData ? customizeJsData.contactDataViewConfiguration : undefined
                }
            />
            <Box marginTop={1} width={1}>
                <Grid container item spacing={1}>
                    {userInfo &&
                        userInfo.roles.includes('Vertriebspartner') &&
                        customizeJsData &&
                        customizeJsData.contactDataViewConfiguration.activateSalesPartnerEmail === true && (
                            <>
                                <Grid item xs={12}>
                                    <Typography variant="caption">{t('salesPartnerEmailText')}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        {...TestIDs.GET_CONTACT_DATA('NO_CUSTOMER_EMAIL', 'PERSONAL')}
                                        control={
                                            <Checkbox
                                                onClick={(): void => {
                                                    if (setContactData) {
                                                        setContactData({
                                                            ...contactData,
                                                            useSalesPartnerEmail: !contactData.useSalesPartnerEmail,
                                                        })
                                                        if (!contactData.useSalesPartnerEmail) {
                                                            setContactData({
                                                                ...contactData,
                                                                personalEmail:
                                                                    customizeJsData.contactDataViewConfiguration
                                                                        .salesPartnerEmail,
                                                            })
                                                        } else if (
                                                            contactData.useSalesPartnerEmail &&
                                                            customizeJsData.contactDataViewConfiguration
                                                                .salesPartnerEmail === contactData.personalEmail
                                                        ) {
                                                            setContactData({
                                                                ...contactData,
                                                                personalEmail: '',
                                                            })
                                                        }
                                                    } else {
                                                        const updateData: Partial<ContactDataState> = {
                                                            useSalesPartnerEmail: !contactData.useSalesPartnerEmail,
                                                        }
                                                        if (!contactData.useSalesPartnerEmail) {
                                                            updateData.personalEmail =
                                                                customizeJsData.contactDataViewConfiguration.salesPartnerEmail
                                                        } else if (
                                                            contactData.useSalesPartnerEmail &&
                                                            customizeJsData.contactDataViewConfiguration
                                                                .salesPartnerEmail === contactData.personalEmail
                                                        ) {
                                                            updateData.personalEmail = ''
                                                        }
                                                        setContactDataPartial(updateData)
                                                    }
                                                }}
                                                style={{ color: colors.main, padding: 0 }}
                                            />
                                        }
                                        label={t('customerNoEmail')}
                                        checked={contactData.useSalesPartnerEmail}
                                    />
                                </Grid>
                            </>
                        )}
                    <Grid item xs={12} md={9}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('EMAIL', 'PERSONAL')}
                            inputProps={{
                                maxLength: 50,
                                className: inLineErrors.includes('email') ? 'TextError' : '',
                            }}
                            error={inLineErrors.includes('email')}
                            helperText={
                                inLineErrors.includes('email') &&
                                t('error.' + ViewType.CONTACT_DATA + '.emailFormatIsNotValid')
                            }
                            fullWidth={true}
                            label={t('contactDataStrings.email')}
                            variant={'outlined'}
                            value={contactData.personalEmail}
                            onChange={(e): void =>
                                setContactData
                                    ? setContactData({
                                          ...contactData,
                                          personalEmail: e.currentTarget.value,
                                      })
                                    : setContactDataPartial({ personalEmail: e.currentTarget.value })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <InputMask
                            mask={'99.99.9999'}
                            alwaysShowMask={false}
                            placeholder={''}
                            value={contactData.personalBirthDate}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                                setContactData
                                    ? setContactData({
                                          ...contactData,
                                          personalBirthDate: e.currentTarget.value,
                                      })
                                    : setContactDataPartial({ personalBirthDate: e.target.value })
                            }
                        >
                            {(): JSX.Element => (
                                <TextField
                                    InputProps={{
                                        className: invalidBirthday.length > 0 ? 'TextError' : '',
                                    }}
                                    fullWidth={true}
                                    label={t('contactDataStrings.birthDate')}
                                    variant={'outlined'}
                                    error={invalidBirthday.length > 0}
                                    helperText={
                                        invalidBirthday.length > 0 &&
                                        t('error.' + ViewType.CONTACT_DATA + '.birthDate.' + invalidBirthday)
                                    }
                                    {...TestIDs.GET_CONTACT_DATA('BIRTH_DATE', 'PERSONAL')}
                                />
                            )}
                        </InputMask>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Typography variant="subtitle1" style={{ color: colors.black, fontSize: '14px' }}>
                            Die Leipziger Stadtwerke können dem Kunden unter den Voraussetzungen des § 7 Abs. 3 UWG
                            Werbung für eigene ähnliche Waren oder Dienstleistungen per E-Mail zusenden. Der Kunde kann
                            der Verwendung seiner angegebenen E-Mail-Adresse zu Werbezwecken jederzeit widersprechen,
                            ohne dass ihm hierfür andere als die Übermittlungskosten nach Basistarifen (reguläre Porto-
                            oder Telekommunikationskosten) entstehen. Der Widerspruch ist zu richten an: Stadtwerke
                            Leipzig GmbH, Postfach 10 06 14, 04006 Leipzig, glasfaser.stadtwerke@L.de.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('TELEPHONE', 'PERSONAL')}
                            fullWidth={true}
                            label={t('contactDataStrings.telephone')}
                            variant={'outlined'}
                            value={contactData.personalTelephone}
                            onChange={(e): void =>
                                setContactData
                                    ? setContactData({
                                          ...contactData,
                                          personalTelephone: e.currentTarget.value,
                                      })
                                    : setContactDataPartial({ personalTelephone: e.currentTarget.value })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            {...TestIDs.GET_CONTACT_DATA('MOBILE_PHONE', 'PERSONAL')}
                            fullWidth={true}
                            label={t('contactDataStrings.mobilePhone')}
                            variant={'outlined'}
                            value={contactData.personalMobilePhone}
                            onChange={(e): void =>
                                setContactData
                                    ? setContactData({
                                          ...contactData,
                                          personalMobilePhone: e.currentTarget.value,
                                      })
                                    : setContactDataPartial({ personalMobilePhone: e.currentTarget.value })
                            }
                        />
                    </Grid>

                    {b2b && (
                        <Grid item xs={12}>
                            <Typography style={{ paddingTop: 10 }} variant="h2">
                                {t('b2bHeadline')}
                            </Typography>
                            <Typography variant="body2">{t('b2bContent')}</Typography>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box width={1}>
                <Box width={1} marginTop={2}>
                    <FormControlLabel
                        {...TestIDs.GET_CONTACT_DATA('TOGGLE', 'DELIVERY')}
                        control={
                            <Checkbox
                                onClick={(): void => {
                                    setContactData
                                        ? setContactData({
                                              ...contactData,
                                              deviatingDeliveryAddress: !contactData.deviatingDeliveryAddress,
                                          })
                                        : setContactDataPartial({
                                              deviatingDeliveryAddress: !contactData.deviatingDeliveryAddress,
                                          })
                                }}
                                style={{ color: colors.main, padding: 0 }}
                            />
                        }
                        label={t('contactDataStrings.deviatingDeliveryAddress')}
                        checked={contactData.deviatingDeliveryAddress}
                    />
                </Box>
                <Collapse
                    className={'ContactDataCollapse'}
                    in={contactData.deviatingDeliveryAddress}
                    unmountOnExit={true}
                    onEnter={(): void => {
                        setContactData
                            ? setContactData({
                                  ...contactData,
                                  deliveryLastName: '',
                                  deliveryName: '',
                                  deliverySalutation: '',
                                  deliveryTitle: '',
                                  deliveryAdditionalAddrInfo: undefined,
                                  deliveryCompany: '',
                                  deliveryCompanyLegalForm: '',
                                  deliveryCity: '',
                                  deliveryHouseNumber: '',
                                  deliveryStreet: '',
                                  deliveryZip: '',
                              })
                            : setContactDataPartial({
                                  deliveryLastName: '',
                                  deliveryName: '',
                                  deliverySalutation: '',
                                  deliveryTitle: '',
                                  deliveryAdditionalAddrInfo: undefined,
                                  deliveryCompany: '',
                                  deliveryCompanyLegalForm: '',
                                  deliveryCity: '',
                                  deliveryHouseNumber: '',
                                  deliveryStreet: '',
                                  deliveryZip: '',
                              })
                    }}
                >
                    <ContactFields
                        testCategory={'DELIVERY'}
                        city={contactData.deliveryCity}
                        houseNumber={contactData.deliveryHouseNumber}
                        isAddressDisabled={false}
                        lastName={contactData.deliveryLastName}
                        name={contactData.deliveryName}
                        salutation={contactData.deliverySalutation}
                        setLastName={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      deliveryLastName: v,
                                  })
                                : setContactDataPartial({ deliveryLastName: v })
                        }
                        setName={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      deliveryName: v,
                                  })
                                : setContactDataPartial({ deliveryName: v })
                        }
                        setSalutation={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      deliverySalutation: v,
                                  })
                                : setContactDataPartial({ deliverySalutation: v })
                        }
                        setTitle={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      deliveryTitle: v,
                                  })
                                : setContactDataPartial({ deliveryTitle: v })
                        }
                        street={contactData.deliveryStreet}
                        title={contactData.deliveryTitle}
                        zip={contactData.deliveryZip}
                        setCity={(v) => setContactDataPartial({ deliveryCity: v })}
                        setHouseNumber={(v) => setContactDataPartial({ deliveryHouseNumber: v })}
                        setStreet={(v) => setContactDataPartial({ deliveryStreet: v })}
                        setZip={(v) => setContactDataPartial({ deliveryZip: v })}
                        handleAddressSelected={(data: PostCodeDetail) => {
                            if (setEditContactAddress) {
                                setEditContactAddress(
                                    data.address.postcode,
                                    data.address.locality,
                                    data.address.street,
                                    data.address.buildingNumber.toString(),
                                    data.address.buildingNumberAddition,
                                    true,
                                )
                            } else {
                                setContactDataPartial({ deliveryZip: data.address.postcode })
                                setContactDataPartial({ deliveryCity: data.address.locality })
                                setContactDataPartial({ deliveryStreet: data.address.street })
                                setContactDataPartial({ deliveryHouseNumber: data.address.buildingNumber.toString() })
                                setContactDataPartial({
                                    deliveryAdditionalAddrInfo: data.address.buildingNumberAddition,
                                })
                            }
                        }}
                        errors={errors}
                        showAutoComplete={true}
                        showAdditionalAddressInfo={true}
                        additionalInfoAddr={contactData.deliveryAdditionalAddrInfo}
                        setAdditionalInfoAddr={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      deliveryAdditionalAddrInfo: v,
                                  })
                                : setContactDataPartial({ deliveryAdditionalAddrInfo: v })
                        }
                        company={
                            b2b || salutationIsCompany(contactData.deliverySalutation)
                                ? contactData.deliveryCompany
                                : undefined
                        }
                        setCompany={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      deliveryCompany: v,
                                  })
                                : setContactDataPartial({ deliveryCompany: v })
                        }
                        companyLegalForm={contactData.deliveryCompanyLegalForm}
                        setCompanyLegalForm={(v) =>
                            setContactData
                                ? setContactData({
                                      ...contactData,
                                      deliveryCompanyLegalForm: v,
                                  })
                                : setContactDataPartial({ deliveryCompanyLegalForm: v })
                        }
                        contactDataViewConfiguration={
                            customizeJsData ? customizeJsData.contactDataViewConfiguration : undefined
                        }
                    />
                </Collapse>
                <Box width={1} marginTop={2}>
                    <Typography>{t('contactDataStrings.helperText')}</Typography>
                </Box>
            </Box>
        </>
    )
}

const ContactData = (): JSX.Element => {
    const {
        b2b,
        currentView,
        errors,
        contactData,
        availability,
        areThereAnyMissingFields,
        inLineErrors,
        setContactDataPartial,
        customizeJsData,
        submitButtonTxt,
    } = useContactDataReducer()

    const [userInfo] = useUserInfo()

    return (
        <ViewWrapper
            disabledSubmit={areThereAnyMissingFields.length !== 0}
            header={getRoleAwareTranslationKey(userInfo, 'contactDataHeader')}
            subHeader={getRoleAwareTranslationKey(userInfo, 'contactDataSubheader')}
            viewType={currentView}
            errorCategory={ViewType.CONTACT_DATA}
            submitButtonText={submitButtonTxt}
            disableBackButton={true}
        >
            {renderContactData({
                availability,
                b2b,
                contactData,
                customizeJsData,
                errors,
                inLineErrors,
                setContactDataPartial,
                userInfo,
            })}
        </ViewWrapper>
    )
}

export default ContactData
