import { Box, Grid, Link, Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { AppState } from 'store/store'
import colors from 'utils/colors'

const Footer = (): JSX.Element => {
    const { customize } = useSelector((state: AppState) => ({
        customize: state.generalState.customizeJsData,
    }))
    const { t } = useTranslation()
    const appTitle = customize?.globalConfiguration.title
    const currentYear = new Date().getFullYear()

    return (
        <Box bgcolor={colors.lightGray} pt={2} pb={2} minHeight={{ xs: 80, sm: 40 }}>
            <Grid container>
                <Grid
                    container
                    item
                    xs={12}
                    md={2}
                    alignItems={'center'}
                    direction={'row'}
                    justifyContent={'center'}
                    className={'FooterIconWrapper'}
                >
                    <Box textAlign={{ xs: 'center', sm: 'left' }} marginBottom={{ xs: 2, md: 0 }}>
                        {customize?.globalConfiguration.showFooterLogo && (
                            <img
                                src={
                                    process.env.PUBLIC_URL + '/customize/' + customize?.globalConfiguration.icons.footer
                                }
                                alt={'logo'}
                                className={'FooterIcon'}
                            />
                        )}
                    </Box>
                </Grid>
                <Box flex={1}>
                    <Grid container item xs={12} lg={12} xl={12} className={'FooterLinkWrapper'}>
                        {customize &&
                            customize.globalConfiguration.footer.map((footerLink, index) => (
                                <Box
                                    key={index}
                                    marginRight={index !== customize.globalConfiguration.footer.length - 1 ? 2 : 0}
                                    alignSelf={'center'}
                                >
                                    {footerLink.link !== '' && (
                                        <Link
                                            target="_blank"
                                            className={'FooterLink'}
                                            href={footerLink.link}
                                            color={'secondary'}
                                        >
                                            {footerLink.i18Key === 'copyright' && '© ' + currentYear + ' '}
                                            {t(footerLink.i18Key)}
                                        </Link>
                                    )}
                                    {footerLink.link === '' && (
                                        <Typography className={'FooterLink'} color={'secondary'} variant={'h5'}>
                                            {footerLink.i18Key === 'copyright' && '© ' + currentYear + ' '}
                                            {t(footerLink.i18Key)}
                                        </Typography>
                                    )}
                                </Box>
                            ))}

                        <Typography
                            className={'FooterLink'}
                            color={'primary'}
                            variant={'subtitle2'}
                            style={{ position: 'absolute', right: 0, bottom: 0, display: 'none' }}
                        >
                            Version: {appTitle} Iteration: {process.env.REACT_APP_BUILD_NUMBER}
                        </Typography>
                    </Grid>
                </Box>
            </Grid>
        </Box>
    )
}

export default Footer
