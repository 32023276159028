import { cloneDeep } from 'lodash'
import { PersistState } from 'redux-persist'
import { ProductCategory, VoucherData } from '../../graphql/types'
import { Customize } from '../../utils/customize'
import { pathToViewType } from '../../utils/testable/pathToViewType'
import productCategoriesDependency from '../../utils/testable/productCategoriesDependency'
import {
    removeDependingOption,
    removeHardwareSelection,
    resolveExcludedOption,
    resolveRequiredOption,
} from '../../utils/testable/resolveDependenciesFromArticles'
import { checkIfWeShouldAddPortabilityPage, updatePagesList } from '../../utils/testable/updatePagesList'
import GeneralStateActions, { GeneralStateAction } from './GeneralState.actions'

export enum ViewType {
    AVAILABILITY_CHECK = 'availabilityCheck',
    PRODUCT_CATEGORIES_SELECTION = 'productCategoriesSelection',
    PRODUCT_SELECTION = 'productSelection',
    PRODUCT_TYPE_SELECTION = 'productTypeSelection',
    OPTIONS_CONFIGURATION = 'optionsConfiguration',
    VZF_VIEW = 'vzfView',
    VZF_LANDINGPAGE = 'vzfLandingPage',
    CONTACT_DATA = 'contactData',
    LANDLINE_CONTRACT_OPTIONS = 'landlineContractOptions',
    PORTABILITY_DETAILS = 'portabilityDetails',
    BANK_DETAILS = 'bankDetails',
    INSTALLATION_DETAILS = 'installationDetails',
    DESIRED_DATE = 'desiredDate',
    MANUAL_VOUCHER_REDEMPTION = 'manualVoucherRedemption',
    ORDER_OVERVIEW = 'orderOverview',
    ORDER_CONFIRMATION = 'orderConfirmation',
    EDIT = 'edit',
    EDIT_PORTABILITY = 'editPortability',
    ORDER_LOAD = 'ORDER_LOAD',
}

export enum OnEditClickTypes {
    ADDRESS = 'ADDRESS',
    INSTALLATION = 'INSTALLATION',
    BANK = 'BANK',
    TELEPHONE = 'TELEPHONE',
    DESIREDDATE = 'DESIREDDATE',
    PORTABILITY = 'PORTABILITY',
}

export enum OptionType {
    CHECKBOX = 'CHECKBOX',
    RADIO = 'RADIO',
}

export interface Address {
    zip: string
    city: string
    street: string
    houseNumber: string
}

export interface LoadState {
    errors?: string
    loading: boolean
}

export interface Page {
    path: string
}

export interface SelectedOptionCategory {
    id: string
    selectedOptions: string[]
}

export interface SelectedProductType {
    id: string
    optionCategories: SelectedOptionCategory[]
}

export interface SelectedProduct {
    id: string
    productTypes: SelectedProductType[]
}

export interface SelectedProductCategory {
    id: string
    selectedProduct?: SelectedProduct | null
}
export interface InstallationDetailsState {
    floorInformation: string
    flatPosition: string
    flatNumber: string
    junctionBox: string
    houseConnection: string
    houseConnectionInput: string
    opticFiber: string
    opticFiberInput: string
    installationService: number
}

export interface Error {
    category: string | ViewType
    errors: string[]
}

const initialLoadState: LoadState = {
    errors: undefined,
    loading: false,
}

export interface GeneralState {
    availableProductCategories: ProductCategory[]
    configuration: Map<string, string | string[]>
    optionsMultipleSelect: Map<string, number>
    currentPage: number
    currentView: ViewType
    customizeJsData?: Customize
    desiredDate: Date | null
    earliestDatePossible: boolean
    redeemManualVoucher: boolean
    voucherCode: string
    voucher: VoucherData[]
    errors: Error[]
    installationDetails: InstallationDetailsState
    loadState: LoadState
    noProduct: boolean
    pagesList: Page[]
    requiredTelephoneField: string[]
    selectedProductCategories: SelectedProductCategory[]
    inLineErrors: string[]
    startOfMarketing: Date | undefined
    startOfDelivery: Date | undefined
    vzfID: string
    vzfMail: string
    orderID: string
    editType?: OnEditClickTypes
    isOnBack: boolean
    _persist: PersistState
}

export const initialPagesList: Page[] = [
    // Availability Check
    {
        path: '/',
    },
    // Product Category Selection
    {
        path: '/Produktkategorieauswahl',
    },
    // Product Selections
    // Option Selections
    // VERTRAGSZUSAMMENFASSUNG - Contract Overview before entering personal data
    {
        path: '/VZF',
    },
    {
        path: '/VZFLandingpage',
    },
    // Contact Data
    {
        path: '/Kontaktdaten',
    },
    // Telephone Configuration
    //{
    //path: '/FestnetzVertragsOptionen',
    //},
    // Portability Numbers
    // Bank Data
    {
        path: '/Zahlungsweise',
    },
    // Installation Details
    {
        path: '/Installationsdetails',
    },
    // // Manual voucher redemption
    //{
    //path: '/Gutscheineingabe',
    //},
    // Desired installation date
    {
        path: '/Wunschtermin',
    },
    // Order Overview
    {
        path: '/Bestellüberprüfung',
    },
    // Order Confirmation
    {
        path: '/VielenDank',
    },
    // Edit
    {
        path: '/Bearbeiten',
    },
    // Edit Portability
    {
        path: '/RufnummernmitnahmeBearbeiten',
    },
]

export const initialGeneralState: GeneralState = {
    availableProductCategories: [],
    configuration: new Map<string, string | string[]>(),
    optionsMultipleSelect: new Map<string, number>(),
    currentPage: 0,
    currentView: ViewType.AVAILABILITY_CHECK,
    customizeJsData: undefined,
    desiredDate: null,
    earliestDatePossible: true,
    redeemManualVoucher: false,
    isOnBack: false,
    voucherCode: '',
    voucher: [],
    errors: [],
    installationDetails: {
        floorInformation: '',
        flatPosition: '',
        flatNumber: '',
        junctionBox: '',
        houseConnection: '',
        houseConnectionInput: '',
        opticFiber: '',
        opticFiberInput: '',
        installationService: 0,
    },
    loadState: initialLoadState,
    noProduct: false,
    pagesList: initialPagesList,
    requiredTelephoneField: [],
    selectedProductCategories: [],
    inLineErrors: [],
    startOfDelivery: undefined,
    startOfMarketing: undefined,
    vzfID: '',
    vzfMail: '',
    orderID: '',
    _persist: { rehydrated: false, version: 1 } as PersistState,
}

function GeneralStateReducer(state: GeneralState = initialGeneralState, action: GeneralStateActions): GeneralState {
    const currentConfiguration = new Map<string, string | string[]>(state.configuration)
    switch (action.type) {
        case GeneralStateAction.SET_TO_INIT_STATE:
            currentConfiguration.clear()
            const copyState = cloneDeep(state)
            initialGeneralState.optionsMultipleSelect = new Map<string, number>()
            return {
                ...initialGeneralState,
                configuration: currentConfiguration,
                customizeJsData: copyState.customizeJsData,
            }
        case GeneralStateAction.UPDATE_PAGELIST:
            return {
                ...state,
                pagesList: updatePagesList(
                    state.availableProductCategories,
                    state.selectedProductCategories,
                    [...initialPagesList],
                    action.payload.B2B,
                    state.customizeJsData,
                ),
            }
        case GeneralStateAction.SET_GENERAL_STATE_PARTIAL:
            return {
                ...state,
                ...action.payload,
            }
        case GeneralStateAction.SET_VFZMAIL:
            return {
                ...state,
                vzfMail: action.payload,
            }
        case GeneralStateAction.SET_ORDERID:
            return {
                ...state,
                orderID: action.payload,
            }
        case GeneralStateAction.SET_EDIT_TYPE:
            return {
                ...state,
                editType: action.payload,
            }
        case GeneralStateAction.SET_ON_BACK:
            return {
                ...state,
                isOnBack: action.payload,
            }
        case GeneralStateAction.SET_INLINE_ERRORS:
            return {
                ...state,
                inLineErrors: action.payload,
            }
        case GeneralStateAction.SELECT_PRODUCT_CATEGORY: {
            const newSelectedProductCategories = productCategoriesDependency(
                state.selectedProductCategories,
                action.payload.id,
                state.customizeJsData?.productCategories,
            )
            return {
                ...state,
                selectedProductCategories: newSelectedProductCategories,
                pagesList: updatePagesList(
                    state.availableProductCategories,
                    newSelectedProductCategories,
                    [...initialPagesList],
                    action.payload.B2B,
                    state.customizeJsData,
                ),
            }
        }
        case GeneralStateAction.SELECT_PRODUCT: {
            // Create a copy of the array to modify it
            const selectedProductCategories = [...state.selectedProductCategories]
            // Iterate over all the categories
            for (let i = 0; i < selectedProductCategories.length; i++) {
                const productCategoryToCheck = selectedProductCategories[i]
                const productCategoryData = state.availableProductCategories.find(
                    (cat) => cat.id === productCategoryToCheck.id,
                )

                // If we have a match and the data is found
                if (
                    productCategoryToCheck.id === action.payload.productCategoryId &&
                    productCategoryData !== undefined
                ) {
                    const productData = productCategoryData.products.find(
                        (prod) => prod.id === action.payload.productId,
                    )
                    if (productData === undefined) return { ...state }

                    const productTypes: SelectedProductType[] = []

                    // Add the belonging product types
                    productData.productTypes.forEach((prodType) => {
                        if (!prodType.optional) {
                            productTypes.push({ id: prodType.id, optionCategories: [] })
                        }
                    })

                    productCategoryToCheck.selectedProduct = { id: action.payload.productId, productTypes }
                    return {
                        ...state,
                        selectedProductCategories,
                        pagesList: updatePagesList(
                            state.availableProductCategories,
                            selectedProductCategories,
                            [...initialPagesList],
                            action.payload.B2B,
                            state.customizeJsData,
                        ),
                    }
                }
            }
            // If nothing has changed, we return the previous state (This shouldn't happen)
            return state
        }
        case GeneralStateAction.SELECT_PRODUCT_TYPE: {
            // Create a copy of the array to modify it
            const selectedProductCategories = [...state.selectedProductCategories]
            // Iterate over all the categories
            for (let i = 0; i < selectedProductCategories.length; i++) {
                const productCategoryToCheck = selectedProductCategories[i]
                // If we have a match
                if (
                    productCategoryToCheck.selectedProduct &&
                    productCategoryToCheck.id === action.payload.productCategoryId
                ) {
                    const index = productCategoryToCheck.selectedProduct.productTypes.findIndex(
                        (value) => value.id === action.payload.productTypeId,
                    )
                    if (index >= 0) {
                        productCategoryToCheck.selectedProduct.productTypes.splice(index, 1)
                    } else {
                        productCategoryToCheck.selectedProduct.productTypes.push({
                            id: action.payload.productTypeId,
                            optionCategories: [],
                        })
                    }
                    return {
                        ...state,
                        selectedProductCategories,
                        pagesList: updatePagesList(
                            state.availableProductCategories,
                            selectedProductCategories,
                            [...initialPagesList],
                            action.payload.B2B,
                            state.customizeJsData,
                        ),
                    }
                }
            }
            // If nothing has changed, we return the previous state (This shouldn't happen)
            return state
        }
        case GeneralStateAction.SELECT_PRODUCT_OPTION: {
            // Create a copy of the array to modify it
            const selectedProductCategories = [...state.selectedProductCategories]
            // Iterate over all the categories
            for (let i = 0; i < selectedProductCategories.length; i++) {
                const productCategoryToCheck = selectedProductCategories[i]
                // If we have a match
                if (productCategoryToCheck.id === action.payload.productCategoryId) {
                    const productToCheck = productCategoryToCheck.selectedProduct
                    // If we have a match on the product
                    if (productToCheck && productToCheck.id === action.payload.productId) {
                        // We look through the selected productTypes
                        if (productToCheck.productTypes.length > 0) {
                            for (let j = 0; j < productToCheck.productTypes.length; j++) {
                                const productTypeToCheck = productToCheck.productTypes[j]
                                // If we have a match
                                if (productTypeToCheck.id === action.payload.productTypeId) {
                                    // We iterate over all the optionCategories
                                    for (let k = 0; k < productTypeToCheck.optionCategories.length; k++) {
                                        const optionCategoryToCheck = productTypeToCheck.optionCategories[k]
                                        // If we have a match
                                        if (optionCategoryToCheck.id === action.payload.optionCategoryId) {
                                            // We know there's at least 1 option in it
                                            // If the option category is a checkbox
                                            if (action.payload.typeOfOptionCategory === OptionType.CHECKBOX) {
                                                // We iterate over all the selected options
                                                for (let l = 0; l < optionCategoryToCheck.selectedOptions.length; l++) {
                                                    const optionToCheck = optionCategoryToCheck.selectedOptions[l]
                                                    // If we have a match
                                                    if (optionToCheck === action.payload.optionId) {
                                                        // We remove this option
                                                        optionCategoryToCheck.selectedOptions.splice(l, 1)
                                                        // If there're no options left
                                                        if (optionCategoryToCheck.selectedOptions.length === 0) {
                                                            // We remove this category from the product
                                                            productTypeToCheck.optionCategories.splice(k, 1)
                                                        }
                                                        // We return the resulting array
                                                        productTypeToCheck.optionCategories = removeDependingOption(
                                                            state.availableProductCategories,
                                                            action.payload.productId,
                                                            action.payload.productTypeId,
                                                            action.payload.optionId,
                                                            productTypeToCheck.optionCategories,
                                                        )
                                                        productTypeToCheck.optionCategories = removeHardwareSelection(
                                                            productTypeToCheck.optionCategories,
                                                            state.availableProductCategories,
                                                            action.payload.productId,
                                                            action.payload.productTypeId,
                                                            action.payload.viewType,
                                                            state.customizeJsData,
                                                        )
                                                        return {
                                                            ...state,
                                                            selectedProductCategories,
                                                            pagesList: updatePagesList(
                                                                state.availableProductCategories,
                                                                selectedProductCategories,
                                                                [...initialPagesList],
                                                                action.payload.B2B,
                                                                state.customizeJsData,
                                                            ),
                                                        }
                                                    }
                                                }
                                                // If we don't have this option selected we just add it
                                                optionCategoryToCheck.selectedOptions.push(action.payload.optionId)
                                                productTypeToCheck.optionCategories = resolveRequiredOption(
                                                    state.availableProductCategories,
                                                    action.payload.productId,
                                                    action.payload.productTypeId,
                                                    action.payload.optionId,
                                                    productTypeToCheck.optionCategories,
                                                )
                                                productTypeToCheck.optionCategories = resolveExcludedOption(
                                                    state.availableProductCategories,
                                                    action.payload.productId,
                                                    action.payload.productTypeId,
                                                    action.payload.optionId,
                                                    productTypeToCheck.optionCategories,
                                                )
                                                productTypeToCheck.optionCategories = removeHardwareSelection(
                                                    productTypeToCheck.optionCategories,
                                                    state.availableProductCategories,
                                                    action.payload.productId,
                                                    action.payload.productTypeId,
                                                    action.payload.viewType,
                                                    state.customizeJsData,
                                                )
                                                // We return the resulting array
                                                return {
                                                    ...state,
                                                    selectedProductCategories,
                                                    pagesList: updatePagesList(
                                                        state.availableProductCategories,
                                                        selectedProductCategories,
                                                        [...initialPagesList],
                                                        action.payload.B2B,
                                                        state.customizeJsData,
                                                    ),
                                                }
                                                // If the option category is a radio
                                            } else {
                                                // We just substitute the value
                                                optionCategoryToCheck.selectedOptions[0] = action.payload.optionId
                                                productTypeToCheck.optionCategories = removeHardwareSelection(
                                                    productTypeToCheck.optionCategories,
                                                    state.availableProductCategories,
                                                    action.payload.productId,
                                                    action.payload.productTypeId,
                                                    action.payload.viewType,
                                                    state.customizeJsData,
                                                )

                                                return {
                                                    ...state,
                                                    selectedProductCategories,
                                                    pagesList: updatePagesList(
                                                        state.availableProductCategories,
                                                        selectedProductCategories,
                                                        [...initialPagesList],
                                                        action.payload.B2B,
                                                        state.customizeJsData,
                                                    ),
                                                }
                                            }
                                        }
                                    }
                                    // If we don't have this category inside of the productType
                                    productTypeToCheck.optionCategories.push({
                                        id: action.payload.optionCategoryId,
                                        selectedOptions: [action.payload.optionId],
                                    })
                                    productTypeToCheck.optionCategories = resolveRequiredOption(
                                        state.availableProductCategories,
                                        action.payload.productId,
                                        action.payload.productTypeId,
                                        action.payload.optionId,
                                        productTypeToCheck.optionCategories,
                                    )
                                    productTypeToCheck.optionCategories = resolveExcludedOption(
                                        state.availableProductCategories,
                                        action.payload.productId,
                                        action.payload.productTypeId,
                                        action.payload.optionId,
                                        productTypeToCheck.optionCategories,
                                    )
                                    productTypeToCheck.optionCategories = removeHardwareSelection(
                                        productTypeToCheck.optionCategories,
                                        state.availableProductCategories,
                                        action.payload.productId,
                                        action.payload.productTypeId,
                                        action.payload.viewType,
                                        state.customizeJsData,
                                    )

                                    return {
                                        ...state,
                                        selectedProductCategories,
                                        pagesList: updatePagesList(
                                            state.availableProductCategories,
                                            selectedProductCategories,
                                            [...initialPagesList],
                                            action.payload.B2B,
                                            state.customizeJsData,
                                        ),
                                    }
                                }
                            }
                        }
                        // If we didn't find a matching productType OR
                        // If we don't have any productType yet, we add it to the product
                        let optionCategories: SelectedOptionCategory[] = [
                            {
                                id: action.payload.optionCategoryId,
                                selectedOptions: [action.payload.optionId],
                            },
                        ]
                        optionCategories = resolveRequiredOption(
                            state.availableProductCategories,
                            action.payload.productId,
                            action.payload.productTypeId,
                            action.payload.optionId,
                            optionCategories,
                        )
                        optionCategories = resolveExcludedOption(
                            state.availableProductCategories,
                            action.payload.productId,
                            action.payload.productTypeId,
                            action.payload.optionId,
                            optionCategories,
                        )
                        optionCategories = removeHardwareSelection(
                            optionCategories,
                            state.availableProductCategories,
                            action.payload.productId,
                            action.payload.productTypeId,
                            action.payload.viewType,
                            state.customizeJsData,
                        )
                        productToCheck.productTypes.push({
                            id: action.payload.productTypeId,
                            optionCategories: optionCategories,
                        })
                        return {
                            ...state,
                            selectedProductCategories,
                            pagesList: updatePagesList(
                                state.availableProductCategories,
                                selectedProductCategories,
                                [...initialPagesList],
                                action.payload.B2B,
                                state.customizeJsData,
                            ),
                        }
                    }
                }
            }
            // If nothing has changed, we return the previous state (This shouldn't happen)
            return state
        }
        case GeneralStateAction.SET_PRODUCT_OPTION_MULTIPLE:
            state.optionsMultipleSelect.set(action.payload.id, action.payload.counter)
            return state
        case GeneralStateAction.SET_TO_INIT_CONFIG_STATE:
            currentConfiguration.clear()
            return {
                ...state,
                configuration: currentConfiguration,
            }
        case GeneralStateAction.SET_CONFIG_VALUE:
            currentConfiguration.set(action.payload.identifier, action.payload.value)
            if (state.currentView === ViewType.LANDLINE_CONTRACT_OPTIONS) {
                state.pagesList = checkIfWeShouldAddPortabilityPage(
                    currentConfiguration,
                    state.pagesList,
                    state.customizeJsData,
                )
            }
            return {
                ...state,
                configuration: currentConfiguration,
            }
        case GeneralStateAction.TOGGLE_CONFIG_VALUE:
            const currentValues = currentConfiguration.get(action.payload.identifier)
            if (currentValues) {
                if (typeof currentValues !== 'string') {
                    if (currentValues.includes(action.payload.value)) {
                        const actualValueIndex = currentValues.findIndex((value) => value === action.payload.value)
                        currentValues.splice(actualValueIndex, 1)
                        if (currentValues.length === 0) {
                            currentConfiguration.delete(action.payload.identifier)
                        } else {
                            currentConfiguration.set(action.payload.identifier, currentValues)
                        }
                    } else {
                        currentValues.push(action.payload.value)
                        currentConfiguration.set(action.payload.identifier, currentValues)
                    }
                }
            } else {
                currentConfiguration.set(action.payload.identifier, [action.payload.value])
            }
            return {
                ...state,
                configuration: currentConfiguration,
            }
        case GeneralStateAction.SET_AVAILABLE_PRODUCT_CATEGORIES: {
            return {
                ...state,
                availableProductCategories: action.payload.list,
                selectedProductCategories: [],
                pagesList: updatePagesList(
                    state.availableProductCategories,
                    [],
                    [...initialPagesList],
                    action.payload.B2B,
                    state.customizeJsData,
                ),
            }
        }
        case GeneralStateAction.SET_INSTALLATION_DETAILS: {
            return {
                ...state,
                installationDetails: action.payload,
            }
        }
        case GeneralStateAction.SET_TELEPHONE_DETAILS: {
            return {
                ...state,
                configuration: action.payload,
            }
        }
        case GeneralStateAction.SET_LOAD_STATE:
            return {
                ...state,
                loadState: action.payload,
            }
        case GeneralStateAction.SET_CUSTOMIZE_DATA:
            return {
                ...state,
                customizeJsData: action.payload,
            }
        case GeneralStateAction.SET_DESIRED_DATE:
            return {
                ...state,
                desiredDate: action.payload,
            }
        case GeneralStateAction.SET_EARLIEST_DATE_POSSIBLE:
            let desiredDate = state.desiredDate
            if (!action.payload) {
                desiredDate = null
            }
            return {
                ...state,
                desiredDate: desiredDate,
                earliestDatePossible: action.payload,
            }
        case GeneralStateAction.SET_REDEEM_MANUAL_VOUCHER:
            return {
                ...state,
                redeemManualVoucher: action.payload,
            }
        case GeneralStateAction.SET_VOUCHER_CODE:
            return {
                ...state,
                voucherCode: action.payload,
            }
        case GeneralStateAction.SET_REQUIRED_TELEPHONE_FIELD:
            return {
                ...state,
                requiredTelephoneField: action.payload,
            }
        case GeneralStateAction.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload,
                currentView: pathToViewType(state.pagesList[action.payload].path),
            }
        case GeneralStateAction.RESET_GENERAL_STATE:
            return initialGeneralState
        case GeneralStateAction.SET_GENERAL_STATE:
            return action.payload
        case GeneralStateAction.ADD_ERROR_TO_ERROR_CATEGORY: {
            const newErrors = [...state.errors]
            const categoryToModify = newErrors.find((error) => error.category === action.payload.category)
            if (categoryToModify === undefined) {
                newErrors.push({ category: action.payload.category, errors: [action.payload.error] })
            } else {
                if (categoryToModify.errors.find((error) => error === action.payload.error) === undefined) {
                    categoryToModify.errors.push(action.payload.error)
                }
            }
            return {
                ...state,
                errors: [...newErrors],
            }
        }
        case GeneralStateAction.CLEAR_ERRORS:
            return {
                ...state,
                errors: [],
            }
        case GeneralStateAction.NO_PRODUCTS:
            return {
                ...state,
                noProduct: action.payload,
            }
        case GeneralStateAction.CLEAR_ERROR_CATEGORY:
            return {
                ...state,
                errors: state.errors.filter((error) => error.category !== action.payload),
            }
        case GeneralStateAction.REMOVE_ERROR_FROM_ERROR_CATEGORY:
            const newErrors = [...state.errors]
            const categoryToModify = newErrors.find((error) => error.category === action.payload.category)
            if (categoryToModify === undefined) return state
            categoryToModify.errors = categoryToModify.errors.filter((error) => error !== action.payload.error)
            if (categoryToModify.errors.length === 0) {
                return {
                    ...state,
                    errors: newErrors.filter((error) => error.category !== categoryToModify.category),
                }
            }
            return {
                ...state,
                errors: newErrors,
            }
        case GeneralStateAction.SET_ENTIRE_CONFIG:
            return {
                ...state,
                configuration: action.payload,
            }
        case GeneralStateAction.ADD_VOUCHERS:
            const newVoucherArr: VoucherData[] = [...state.voucher]
            action.payload.forEach((voucher) => {
                let alreadyInState = false
                state.voucher.forEach((stateVoucher) => {
                    if (voucher.id === stateVoucher.id) {
                        alreadyInState = true
                    }
                })

                if (!alreadyInState) {
                    newVoucherArr.push(voucher)
                }
            })
            return {
                ...state,
                voucher: newVoucherArr,
            }
        case GeneralStateAction.REMOVE_ALL_VOUCHERS:
            return {
                ...state,
                voucher: [],
            }
        default:
            return state
    }
}

export default GeneralStateReducer
