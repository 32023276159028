import { Grid, Link, Typography } from '@material-ui/core'
import ConfigurationItem, { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import { useTranslation } from 'react-i18next'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'
import { ConfigurationLink } from 'utils/customize'
import TestIDs from 'utils/TestIDs'

export interface ConfigurationCheckProps {
    title: string
    helpText?: string
    selected: string[]
    link?: ConfigurationLink
    marginBottom?: number | string
    marginTop?: number | string
    titleHidden?: boolean
    options: ConfigurationItemOption[]
    onSelect: (value: string) => void
}

const ConfigurationCheck = (props: ConfigurationCheckProps): JSX.Element => {
    const { t } = useTranslation()
    const { title, helpText, selected, link, options, marginBottom, marginTop, onSelect, titleHidden } = props

    return (
        <Grid
            container
            spacing={1}
            style={{
                marginBottom: marginBottom !== undefined ? marginBottom : '50px',
                marginTop: marginTop !== undefined ? marginTop : '0px',
            }}
        >
            <Grid item xs={12}>
                {(titleHidden === undefined || titleHidden === false) && (
                    <Typography {...TestIDs.GET_CONFIGURATION('CHECKBOX', 'TITLE')} variant={'h4'}>
                        {t(title)}
                    </Typography>
                )}
                {link && (
                    <Link
                        href={link.url}
                        target={'_blank'}
                        {...TestIDs.GET_CONFIGURATION('CHECKBOX', 'LINK')}
                        className={'ConfigurationLink'}
                    >
                        {t(link.text)}
                    </Link>
                )}
            </Grid>
            {options.map(
                (option: ConfigurationItemOption): JSX.Element => (
                    <ConfigurationItem
                        key={option.value + option.label}
                        selected={selected ? selected.includes(option.value) : false}
                        option={option}
                        type={OptionType.CHECKBOX}
                        onSelect={onSelect}
                    />
                ),
            )}
            {helpText && (
                <Grid item xs={12}>
                    <Typography
                        {...TestIDs.GET_CONFIGURATION('CHECKBOX', 'HELP_TEXT')}
                        variant={'subtitle1'}
                        className={'ConfigurationHelpText'}
                    >
                        {t(helpText)}
                    </Typography>
                </Grid>
            )}
        </Grid>
    )
}

export default ConfigurationCheck
