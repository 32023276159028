const colors = {
    black: '#000000',
    color1: '#222222',
    color2: '#F2F2F2',
    color3: '#CFA22E',
    color4: '#fbc10f',
    darkGray: '#949494',
    basketGray: '#909090',
    darkerGray: '#737373',
    darkerGray2: '#747474',
    main: '#032c58',
    mainAlternative: '#032c58',
    lightGreen: 'rgba(3, 44, 88, 0.5)',
    header: '#FFFFFF',
    lightGray: '#F5F5F5',
    lightGray2: '#E6E6E6',
    midGray: '#C4C4C4',
    warning: '#EB5757',
    disabledProgress: '#F5F5F5',
    lightWarning: 'rgba(235, 87, 87, 0.20)',
    white: '#FFFFFF',
    basketInfoIcon: 'rgba(3, 44, 88, 1)',
    basketPriceBox: '#5a79a5',
    buttonPrimaryColor: '#fbc10f',
}

export default colors
