import { Box } from '@material-ui/core'
import OptionItem, { OptionItemContent } from 'components/OptionItem/OptionItem'
import { OptionType } from 'store/GeneralState/GeneralState.reducer'

export interface OptionsCategoryProps {
    categoryType: OptionType
    categoryId: string
    header: string
    subheader?: string
    options: OptionItemContent[]
    selected: string[]
    optionsMultipleSelect: Map<string, number>
    onSelect: (id: string) => void
    increaseSelection: (id: string, min: number, max: number) => void
    decreaseSelection: (id: string, min: number, max: number) => void
    disabled: string[]
}

const OptionsCategory = (props: OptionsCategoryProps): JSX.Element => {
    const {
        categoryId,
        categoryType,
        //header,
        //subheader,
        options,
        selected,
        optionsMultipleSelect,
        onSelect,
        increaseSelection,
        decreaseSelection,
        disabled,
    } = props

    return (
        <Box key={categoryId} width={1}>
            {/* <Typography variant={'h2'} className={'OptionItemCategoryTitle'}>
                {header}
            </Typography>
            {subheader && (
                <Typography className={'OptionItemSubtitleLink'} dangerouslySetInnerHTML={{ __html: subheader }} />
            )} */}
            <Box width={1} marginTop={2}>
                {options.map(
                    (option: OptionItemContent): JSX.Element => (
                        <Box mb={'9px'} key={option.id + option.title}>
                            <OptionItem
                                content={option}
                                selected={selected.some((selected) => selected === option.id)}
                                disabled={disabled.some((disabled) => disabled === option.id)}
                                type={categoryType}
                                onSelect={onSelect}
                                multiSelectedCounter={
                                    optionsMultipleSelect.get(option.id) ? optionsMultipleSelect.get(option.id) : 1
                                }
                                increaseSelection={increaseSelection}
                                decreaseSelection={decreaseSelection}
                            />
                        </Box>
                    ),
                )}
            </Box>
        </Box>
    )
}

export default OptionsCategory
