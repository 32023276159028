import { OrderDataInput } from 'graphql/types'
import { initialAvailabilityCheckState } from 'store/AvailabilityCheck/AvailabilityCheck.reducer'
import { BankDetailsState, initialBankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import { ContactDataState, initialContactDataState } from 'store/ContactData/ContactData.reducer'
import { initialGeneralState } from 'store/GeneralState/GeneralState.reducer'
import { PortabilityViewFields, initialPortabilityState } from 'store/PortabilityState/PortabilityState.reducer'
import { AppState } from 'store/store'
import { revertCRMDate } from './testable/toCRMDate'

export const convertOrderDataToState = (input: OrderDataInput): AppState => {
    let availabilityCheckState = initialAvailabilityCheckState
    if (input.personalAddress) {
        availabilityCheckState = {
            ...availabilityCheckState,
            selectedCity: input.personalAddress.city,
            selectedHouseNumber: input.personalAddress.houseNumber,
            selectedStreet: input.personalAddress.street,
            zip: input.personalAddress.zipcode,
        }
    }

    let birthDatePersonal = input.personalAddress?.birthDate ?? ''
    if (birthDatePersonal !== '') {
        birthDatePersonal = revertCRMDate(birthDatePersonal)
    }

    let contactDataState: ContactDataState = {
        ...initialContactDataState,
        deviatingBillingAddress: input.deviatingBillingAddress,
        deviatingDeliveryAddress: input.deviatingDeliveryAddress,
        personalBirthDate: birthDatePersonal,
    }

    if (input.personalAddress) {
        contactDataState = {
            ...contactDataState,
            personalEmail: input.personalAddress.email,
            personalLastName: input.personalAddress.lastName,
            personalMobilePhone: input.personalAddress.mobilePhone,
            personalName: input.personalAddress.name,
            personalSalutation: input.personalAddress.salutation,
            personalTelephone: input.personalAddress.telephone,
            personalTitle: input.personalAddress.title,
            company: input.personalAddress.company,
            companyLegalForm: input.personalAddress.companyLegalForm,
            companyRegisterEntry: input.personalAddress.companyRegisterEntry,
            companyLocation: input.personalAddress.companyLocation,
            companyId: input.personalAddress.companyId,
            useSalesPartnerEmail: input.personalAddress.useSalesPartnerEmail,
        }
    }

    if (input.billingAddress) {
        contactDataState = {
            ...contactDataState,
            billingCity: input.billingAddress.city,
            billingHouseNumber: input.billingAddress.houseNumber,
            billingLastName: input.billingAddress.lastName,
            billingName: input.billingAddress.name,
            billingSalutation: input.billingAddress.salutation,
            billingStreet: input.billingAddress.street,
            billingTitle: input.billingAddress.title,
            billingZip: input.billingAddress.zipcode,
            billingCompany: input.billingAddress.company,
            billingCompanyLegalForm: input.billingAddress.companyLegalForm,
        }
    }

    if (input.deliveryAddress) {
        contactDataState = {
            ...contactDataState,
            deliveryCity: input.deliveryAddress.city,
            deliveryHouseNumber: input.deliveryAddress.houseNumber,
            deliveryLastName: input.deliveryAddress.lastName,
            deliveryName: input.deliveryAddress.name,
            deliverySalutation: input.deliveryAddress.salutation,
            deliveryStreet: input.deliveryAddress.street,
            deliveryTitle: input.deliveryAddress.title,
            deliveryZip: input.deliveryAddress.zipcode,
            deliveryCompany: input.deliveryAddress.company,
            deliveryCompanyLegalForm: input.deliveryAddress.companyLegalForm,
        }
    }

    const bankDetailsState: BankDetailsState = {
        ...initialBankDetailsState,
        accountType: input.bankDetails.accountType,
        accountHolderData: {
            city: input.bankDetails.accountHolderData.city,
            houseNumber: input.bankDetails.accountHolderData.houseNumber,
            lastName: input.bankDetails.accountHolderData.lastName,
            name: input.bankDetails.accountHolderData.name,
            salutation: input.bankDetails.accountHolderData.salutation,
            street: input.bankDetails.accountHolderData.street,
            title: input.bankDetails.accountHolderData.title,
            zip: input.bankDetails.accountHolderData.zipcode,
            company: input.bankDetails.accountHolderData.company,
            companyLegalForm: input.bankDetails.accountHolderData.companyLegalForm,
        },
        consentChecked: input.bankDetails.consentChecked,
        differentAccountHolder: input.bankDetails.differentAccountHolder,
        iban: input.bankDetails.iban,
        transfer: input.bankDetails.transfer,
    }
    // TODO: ADD PROVIDERS, MAYBE LOAD THEM NOW TO CHECK IF THE PROVIDER THE USER SELECTED IS STILL AVAILABLE

    let portabilityState = initialPortabilityState
    if (input.portability) {
        portabilityState = {
            ...initialPortabilityState,
            address: {
                city: input.portability?.address.city,
                houseNumber: input.portability?.address.houseNumber,
                street: input.portability?.address.street,
                zip: input.portability?.address.zipcode,
            },
            contractHolderOptions: input.portability?.contractHolderOptions,
            endOfContract:
                input.portability.selectedRadios[2] === PortabilityViewFields.TERMINATED_CONTRACT + 'Yes' &&
                input.portability.endOfContract.trim().length > 0
                    ? revertCRMDate(input.portability.endOfContract)
                    : '',
            phoneOptions: input.portability.phoneOptions,
            selectedProvider: input.portability.selectedProvider,
            selectedRadios: input.portability.selectedRadios,
        }
    }
    // TODO: LOAD THE AVAILABLE PRODUCTS AND CHECK FOR NON EXISTING ONES

    const configuration = new Map<string, string | string[]>()
    if (input.contractData) {
        input.contractData.configuration.forEach((configurationItem) => {
            const valueArray = configurationItem.value.split(',')
            configuration.set(configurationItem.key, valueArray.length > 1 ? [...valueArray] : valueArray[0])
        })
    }

    let generalState = initialGeneralState
    if (input.contractData) {
        generalState = {
            ...initialGeneralState,
            configuration,
            desiredDate: input.contractData.desiredDate
                ? new Date(input.contractData.desiredDate)
                : initialGeneralState.desiredDate,
            installationDetails: input.contractData.installationDetails
                ? input.contractData.installationDetails
                : initialGeneralState.installationDetails,
            selectedProductCategories: input.contractData.selectedProductCategories,
        }
    }

    return {
        availabilityCheck: availabilityCheckState,
        bankDetails: bankDetailsState,
        contactData: contactDataState,
        generalState,
        portabilityState,
    }
}
