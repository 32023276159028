import { Box, Button, Grid, Typography } from '@material-ui/core'
import clsx from 'clsx'
import { format } from 'date-fns'
import { AccountType } from 'graphql/types'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import useURLParams from 'utils/URLParamsContex'
import { getRoleAwareTranslationKey, isSalesPartner, useUserInfo } from 'utils/UserInfoContext'
import { salutationIsCompany } from 'utils/testable/functions'
import { BasketProps } from '../../components/Basket/Basket'
import CheckboxComponent from '../../components/CheckboxComponent'
import CostsTable from '../../components/CostsTable'
import OverviewTable from '../../components/OverviewTable'
import { OverviewTableRow } from '../../components/OverviewTable/OverviewTable'
import ProductTable from '../../components/ProductTable'
import ViewWrapper from '../../components/ViewWrapper'
import { BankDetailsState } from '../../store/BankDetails/BankDetails.reducer'
import { ContactDataState } from '../../store/ContactData/ContactData.reducer'
import { GeneralState, OnEditClickTypes } from '../../store/GeneralState/GeneralState.reducer'
import { PortabilityState } from '../../store/PortabilityState/PortabilityState.reducer'
import colors from '../../utils/colors'
import { OrderOverviewCheckboxCategory, OrderOverviewCheckboxCategoryForLegalTerms } from '../../utils/customize'
import { basketCalculation } from '../../utils/testable/basketCalculation'
import { useOrderOverviewReducer } from './useOrderOverviewReducer'

const OrderOverview = (): JSX.Element => {
    const { t } = useTranslation()
    const {
        address,
        bankDetails,
        contactData,
        disabledSubmit,
        currentView,
        generalState,
        portabilityState,
        landLineContractOptionsExit,
        toggleConfigValue,
        productsText,
        customizeJsData,
        productsInformationSheetsText,
        vzfID,
        onDownloadVZFPDF,
        onEditClick,
        setEditType,
        onDownloadExcel,
    } = useOrderOverviewReducer()
    const { B2B } = useURLParams()
    const basketProps: BasketProps = basketCalculation(address, bankDetails, generalState, B2B, false)
    const [userInfo] = useUserInfo()
    const salesPartner = isSalesPartner(userInfo ? userInfo.roles : [])

    const generateCheckboxes = (checkboxCategory: OrderOverviewCheckboxCategory): JSX.Element => {
        return (
            <Box width={1}>
                {checkboxCategory.header && (
                    <Typography component={'div'} variant={'h2'} className={'SectionCategory'}>
                        {t(currentView + 'Strings.checkboxes.' + checkboxCategory.header)}
                    </Typography>
                )}
                {checkboxCategory.subheader && (
                    <Typography className={'OverviewCheckboxSubheader'}>
                        {t(currentView + 'Strings.checkboxes.' + checkboxCategory.subheader)}
                    </Typography>
                )}
                {checkboxCategory.checkboxes.length > 0 && (
                    <Box flex={1} marginTop={2}>
                        <Grid container>
                            {checkboxCategory.checkboxes.map((value, index) => {
                                let label = currentView + 'Strings.checkboxes.' + value
                                const labelB2B = currentView + 'Strings.checkboxes.' + value + 'B2B'
                                // When label is not equal to the t(label) there is no value for it
                                if (B2B === true && labelB2B !== t(labelB2B)) {
                                    label = labelB2B
                                }
                                const checked = generalState.configuration.get(value) === 'true'
                                return (
                                    <Box key={value} width={1} marginTop={index > 0 ? 1 : 0}>
                                        <Grid item xs={12}>
                                            <CheckboxComponent
                                                checked={checked}
                                                label={t(getRoleAwareTranslationKey(userInfo, label), {
                                                    product: productsText,
                                                    dataSheets: productsInformationSheetsText,
                                                    address:
                                                        address.selectedStreet +
                                                        ' ' +
                                                        address.selectedHouseNumber +
                                                        ' ' +
                                                        address.selectedCity,
                                                })}
                                                onSelect={(): void =>
                                                    toggleConfigValue(value, checked ? 'false' : 'true')
                                                }
                                                testIdentifier={{
                                                    input: 'CHECKBOX',
                                                    viewType: currentView,
                                                    category: value,
                                                }}
                                            />
                                        </Grid>
                                    </Box>
                                )
                            })}
                        </Grid>
                    </Box>
                )}
                {checkboxCategory.helperText && (
                    <Box flex={1} marginTop={2}>
                        <Typography variant={'subtitle1'} className={'OverviewCheckboxHelperText'}>
                            {t(currentView + 'Strings.checkboxes.' + checkboxCategory.helperText)}
                        </Typography>
                    </Box>
                )}
            </Box>
        )
    }

    const generateCheckboxesForLegalTerms = (
        checkboxCategory: OrderOverviewCheckboxCategoryForLegalTerms,
    ): JSX.Element => {
        const areVisibleCheckboxes = (): boolean => {
            const areVisible: boolean[] = []
            if (checkboxCategory.checkboxes.length > 0) {
                for (const cb of checkboxCategory.checkboxes) {
                    if (cb.isDisplayedInB2B === true && B2B === true) {
                        areVisible.push(true)
                    } else {
                        if (cb.isDisplayedInB2B === false && B2B === true) {
                            areVisible.push(false)
                        } else {
                            areVisible.push(true)
                        }
                    }
                }
            }
            return areVisible.some((value) => value === true)
        }
        return (
            <Box width={1} hidden={!areVisibleCheckboxes()}>
                {checkboxCategory.header && (
                    <Typography component={'div'} variant={'h2'} className={'SectionCategory'}>
                        {t(currentView + 'Strings.checkboxes.' + checkboxCategory.header)}
                    </Typography>
                )}
                {checkboxCategory.subheader && (
                    <Typography className={'OverviewCheckboxSubheader'}>
                        {t(currentView + 'Strings.checkboxes.' + checkboxCategory.subheader)}
                    </Typography>
                )}
                <Box flex={1} marginTop={2}>
                    <Grid container>
                        {checkboxCategory.checkboxes.map((checkbox, index) => {
                            let label = currentView + 'Strings.checkboxes.' + checkbox.value
                            const labelB2B = currentView + 'Strings.checkboxes.' + checkbox.value + 'B2B'
                            // When label is not equal to the t(label) there is no value for it
                            if (B2B === true && labelB2B !== t(labelB2B)) {
                                label = labelB2B
                            }
                            const checked = generalState.configuration.get(checkbox.value) === 'true'
                            return (
                                <Box
                                    key={checkbox.value}
                                    width={1}
                                    marginTop={index > 0 ? 1 : 0}
                                    hidden={!checkbox.isDisplayedInB2B && B2B === true}
                                >
                                    <Grid item xs={12}>
                                        <CheckboxComponent
                                            checked={checked}
                                            label={t(getRoleAwareTranslationKey(userInfo, label), {
                                                product: productsText,
                                                dataSheets: productsInformationSheetsText,
                                                address:
                                                    address.selectedStreet +
                                                    ' ' +
                                                    address.selectedHouseNumber +
                                                    ' ' +
                                                    address.selectedCity,
                                            })}
                                            onSelect={(): void =>
                                                toggleConfigValue(checkbox.value, checked ? 'false' : 'true')
                                            }
                                            testIdentifier={{
                                                input: 'CHECKBOX',
                                                viewType: currentView,
                                                category: checkbox.value,
                                            }}
                                        />
                                    </Grid>
                                </Box>
                            )
                        })}
                    </Grid>
                </Box>

                {checkboxCategory.helperText && (
                    <Box flex={1} marginTop={2}>
                        <Typography variant={'subtitle1'} className={'OverviewCheckboxHelperText'}>
                            {t(currentView + 'Strings.checkboxes.' + checkboxCategory.helperText)}
                        </Typography>
                    </Box>
                )}
            </Box>
        )
    }

    const generateRowsForContactData = (contactData: ContactDataState): OverviewTableRow[] => {
        const rows: OverviewTableRow[] = []

        rows.push({
            key: currentView + 'Strings.contactData.personalSalutation',
            value: t(contactData.personalSalutation),
        })

        if (contactData.personalTitle.trim() !== '') {
            rows.push({
                key: currentView + 'Strings.contactData.personalTitle',
                value: contactData.personalTitle,
            })
        }
        rows.push(
            {
                key: currentView + 'Strings.contactData.personalFullName',
                value: contactData.personalName + ' ' + contactData.personalLastName,
            },
            {
                key: currentView + 'Strings.contactData.personalAddress',
                value: `${address.selectedStreet} ${address.selectedHouseNumber}`,
            },
            {
                key: currentView + 'Strings.contactData.empty',
                value: ` ${address.zip} ${address.selectedCity} `,
            },
            {
                key: currentView + 'Strings.contactData.empty',
                value: `${contactData.personalAdditionalAddressInfo ? contactData.personalAdditionalAddressInfo : ''}`,
            },
            {
                key: currentView + 'Strings.contactData.personalEmail',
                value: contactData.personalEmail,
            },
            {
                key: currentView + 'Strings.contactData.personalTelephone',
                value: contactData.personalTelephone,
            },
            {
                key: currentView + 'Strings.contactData.personalMobilePhone',
                value: contactData.personalMobilePhone,
            },
            {
                key: currentView + 'Strings.contactData.personalBirthDate',
                value: contactData.personalBirthDate,
            },
        )

        if (B2B === true) {
            rows.push({
                key: 'contactDataStrings.companyName',
                value: contactData.company,
            })

            if (customizeJsData && customizeJsData.contactDataViewConfiguration) {
                if (customizeJsData.contactDataViewConfiguration.enableLegalForm) {
                    rows.push({
                        key: 'contactDataStrings.companyLegalForm',
                        value: contactData.companyLegalForm,
                    })
                }
                if (customizeJsData.contactDataViewConfiguration.enableCompanyRegisterEntry) {
                    rows.push({
                        key: 'contactDataStrings.companyRegisterEntry',
                        value: contactData.companyRegisterEntry,
                    })
                }
                if (customizeJsData.contactDataViewConfiguration.enableCompanyLocation) {
                    rows.push({
                        key: 'contactDataStrings.companyLocation',
                        value: contactData.companyLocation,
                    })
                }
                if (customizeJsData.contactDataViewConfiguration.enableCompanyTaxID) {
                    rows.push({
                        key: 'contactDataStrings.companyTaxId',
                        value: contactData.companyId,
                    })
                }
            }
        } else {
            // B2B == off but selected company as salutation
            if (salutationIsCompany(contactData.personalSalutation)) {
                rows.push({
                    key: 'contactDataStrings.companyName',
                    value: contactData.company,
                })
                if (
                    customizeJsData &&
                    customizeJsData.contactDataViewConfiguration &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    rows.push({
                        key: 'contactDataStrings.companyLegalForm',
                        value: contactData.companyLegalForm,
                    })
                }
            }
        }

        if (contactData.deviatingDeliveryAddress) {
            rows.push(
                {
                    key: currentView + 'Strings.contactData.deliveryAddress',
                    value: '$',
                    keyVariant: 'h3',
                },
                {
                    key: currentView + 'Strings.contactData.personalSalutation',
                    value: t(contactData.deliverySalutation),
                },
            )

            if (contactData.deliveryTitle.trim() !== '') {
                rows.push({
                    key: currentView + 'Strings.contactData.personalTitle',
                    value: contactData.deliveryTitle,
                })
            }
            rows.push(
                {
                    key: currentView + 'Strings.contactData.personalFullName',
                    value: contactData.deliveryName + ' ' + contactData.deliveryLastName,
                },
                {
                    key: currentView + 'Strings.contactData.personalAddress',
                    value: `${contactData.deliveryStreet} ${contactData.deliveryHouseNumber}`,
                },

                {
                    key: currentView + 'Strings.contactData.empty',
                    value: `${contactData.deliveryZip} ${contactData.deliveryCity}`,
                },

                {
                    key: currentView + 'Strings.contactData.empty',
                    value: `${contactData.deliveryAdditionalAddrInfo ? contactData.deliveryAdditionalAddrInfo : ''}`,
                },
            )

            if (contactData.deliveryCompany.trim().length > 0) {
                rows.push({
                    key: 'contactDataStrings.companyName',
                    value: contactData.deliveryCompany,
                })
                if (
                    customizeJsData &&
                    customizeJsData.contactDataViewConfiguration &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    rows.push({
                        key: 'contactDataStrings.companyLegalForm',
                        value: contactData.deliveryCompanyLegalForm,
                    })
                }
            }
        }

        if (contactData.deviatingBillingAddress) {
            rows.push(
                {
                    key: currentView + 'Strings.contactData.billingAddress',
                    value: '$',
                    keyVariant: 'h3',
                },
                {
                    key: currentView + 'Strings.contactData.personalSalutation',
                    value: t(contactData.billingSalutation),
                },
            )

            if (contactData.billingTitle.trim() !== '') {
                rows.push({
                    key: currentView + 'Strings.contactData.personalTitle',
                    value: contactData.billingTitle,
                })
            }
            rows.push(
                {
                    key: currentView + 'Strings.contactData.personalFullName',
                    value: contactData.billingName + ' ' + contactData.billingLastName,
                },
                {
                    key: currentView + 'Strings.contactData.personalAddress',
                    value: `${contactData.billingStreet} ${contactData.billingHouseNumber} `,
                },
                {
                    key: currentView + 'Strings.contactData.empty',
                    value: `${contactData.billingZip} ${contactData.billingCity}`,
                },

                {
                    key: currentView + 'Strings.contactData.empty',
                    value: `${contactData.billingAdditionalAddrInfo ? contactData.billingAdditionalAddrInfo : ''}`,
                },
            )
            if (contactData.billingCompany.trim().length > 0) {
                rows.push({
                    key: 'contactDataStrings.companyName',
                    value: contactData.billingCompany,
                })
                if (
                    customizeJsData &&
                    customizeJsData.contactDataViewConfiguration &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    rows.push({
                        key: 'contactDataStrings.companyLegalForm',
                        value: contactData.billingCompanyLegalForm,
                    })
                }
            }
        }

        return rows
    }

    // TODO: add the ones that are NOT in the configuration map
    const generateRowsForTelephoneContractData = (
        generalState: GeneralState,
        portabilityState: PortabilityState,
    ): OverviewTableRow[] => {
        const rowsToReturn: OverviewTableRow[] = []

        if (generalState.customizeJsData) {
            rowsToReturn.push({
                key: t(currentView + 'Strings.telephoneContractData.portability.porting'),
                value:
                    portabilityState.phoneOptions[0].areaCode === '' && portabilityState.phoneOptions[0].number === ''
                        ? t(currentView + 'Strings.telephoneContractData.portability.no')
                        : t(currentView + 'Strings.telephoneContractData.portability.yes'),
            })
            if (generalState.customizeJsData.telephoneOptions.portabilityVisible) {
                const portabilityValue = generalState.configuration.get(
                    generalState.customizeJsData.portabilityConfiguration.showPortability.identifier,
                )
                if (
                    portabilityValue === generalState.customizeJsData.portabilityConfiguration.showPortability.wishValue
                ) {
                    rowsToReturn.push({
                        key: t(
                            currentView +
                                'Strings.telephoneContractData.' +
                                generalState.customizeJsData.portabilityConfiguration.showPortability.identifier,
                        ),
                        value: portabilityState.phoneOptions[0].areaCode + portabilityState.phoneOptions[0].number,
                    })

                    portabilityState.phoneOptions.forEach((portabilityTelephone, index) => {
                        if (index > 0) {
                            rowsToReturn.push({
                                key: '',
                                value: portabilityTelephone.areaCode + portabilityTelephone.number,
                            })
                        }
                    })

                    rowsToReturn.push({
                        key: t(currentView + 'Strings.telephoneContractData.portability.provider'),
                        value: portabilityState.selectedProvider,
                    })

                    if (portabilityState.selectedRadios.includes('contractHolderNo')) {
                        rowsToReturn.push({
                            key: t(
                                currentView +
                                    'Strings.telephoneContractData.portability.differentPreviousContractHolder',
                            ),
                            value: t(currentView + 'Strings.telephoneContractData.yes'),
                        })
                        portabilityState.contractHolderOptions.forEach((contractHolder, index) => {
                            rowsToReturn.push({
                                key:
                                    t(
                                        currentView +
                                            'Strings.telephoneContractData.portability.previousContractHolderName',
                                    ) + (portabilityState.contractHolderOptions.length > 1 ? ' ' + (index + 1) : ''),
                                value: contractHolder.firstName + ' ' + contractHolder.lastName,
                            })
                        })
                    }
                    // else {
                    //     rowsToReturn.push({
                    //         key: t(currentView + 'Strings.telephoneContractData.portability.differentContractHolder'),
                    //         value: t(currentView + 'Strings.telephoneContractData.no'),
                    //     })
                    // }

                    if (portabilityState.selectedRadios.includes('addressNo')) {
                        rowsToReturn.push({
                            key: t(
                                currentView + 'Strings.telephoneContractData.portability.differentConnectionAddress',
                            ),
                            value: `${portabilityState.address.street} ${portabilityState.address.houseNumber} ${portabilityState.address.zip} ${portabilityState.address.city}`,
                        })
                    }
                    // else {
                    //     rowsToReturn.push({
                    //         key: t(currentView + 'Strings.telephoneContractData.portability.differentConnectionAddress'),
                    //         value: t(currentView + 'Strings.telephoneContractData.no'),
                    //     })
                    // }

                    if (portabilityState.selectedRadios.includes('terminatedContractNo')) {
                        rowsToReturn.push({
                            key: t(currentView + 'Strings.telephoneContractData.portability.terminatedContract'),
                            value: portabilityState.endOfContract,
                        })
                    } else {
                        rowsToReturn.push({
                            key: t(currentView + 'Strings.telephoneContractData.portability.terminatedContract'),
                            value: t(currentView + 'Strings.telephoneContractData.no'),
                        })
                    }
                }
            }

            generalState.configuration.forEach((value, key) => {
                if (generalState.customizeJsData) {
                    let showEntry = false
                    for (const configuration of generalState.customizeJsData.telephoneOptions.telephoneConfiguration) {
                        if (configuration.type === 'conditional' && configuration.render) {
                            const conditionValue = generalState.configuration.get(
                                configuration.condition?.identifier ?? '',
                            )
                            if (conditionValue !== configuration.condition?.value) continue
                            for (const subConfiguration of configuration.render) {
                                if (subConfiguration.identifier === key) {
                                    showEntry = true
                                }
                            }
                            if (showEntry) break
                        } else if (configuration.identifier === key) {
                            showEntry = true
                            break
                        }
                    }
                    if (
                        showEntry &&
                        key !== 'invoiceSend' &&
                        generalState.customizeJsData &&
                        key !== 'informationServices' &&
                        key !== generalState.customizeJsData.portabilityConfiguration.showPortability.identifier &&
                        generalState.customizeJsData.orderOverview.legalTerms.checkboxes.some(
                            (checkbox) => checkbox.value === key,
                        ) === false &&
                        ((generalState.customizeJsData.orderOverview.newsletters &&
                            generalState.customizeJsData.orderOverview.newsletters.checkboxes.includes(key) ===
                                false) ||
                            generalState.customizeJsData.orderOverview.newsletters === undefined)
                    ) {
                        rowsToReturn.push({
                            key: t(currentView + 'Strings.telephoneContractData.' + key),
                            value:
                                typeof value === 'string'
                                    ? t(currentView + 'Strings.telephoneContractData.' + value)
                                    : value
                                          .map((value) => t(currentView + 'Strings.telephoneContractData.' + value))
                                          .join(', '),
                        })
                    }
                }
            })

            // if (generalState.configuration.get('entryInPhoneBook')) {
            //     const informationServicesConfig = generalState.configuration.get('informationServices') as string[]
            //     const inverseSearchKey =
            //         informationServicesConfig && informationServicesConfig.includes('inverseSearch') ? 'yes' : 'no'

            //     rowsToReturn.push({
            //         key: t(currentView + 'Strings.telephoneContractData.' + 'inverseSearchTitle'),
            //         value: t(currentView + 'Strings.telephoneContractData.' + inverseSearchKey),
            //     })
            // }
        }

        return rowsToReturn
    }

    const generateRowsForBankDetails = (bankDetails: BankDetailsState): OverviewTableRow[] => {
        const rows: OverviewTableRow[] = []
        if (bankDetails.accountType === AccountType.IBAN) {
            rows.push({
                key: currentView + 'Strings.bankDetails.accountType',
                value: currentView + 'Strings.bankDetails.selfpay',
            })
            rows.push({
                key: currentView + 'Strings.bankDetails.iban',
                value: bankDetails.iban,
            })
        } else {
            rows.push({
                key: currentView + 'Strings.bankDetails.accountType',
                value: currentView + 'Strings.bankDetails.transfer',
            })
        }
        if (bankDetails.accountType === 'IBAN') {
            rows.push({
                key: t('bankDetailsStrings.transferMonthlyTitle'),
                value:
                    bankDetails.dayOfTransfer === '15'
                        ? t('bankDetailsStrings.transfer15')
                        : t('bankDetailsStrings.transfer29'),
            })
        }
        if (generalState.configuration.get('invoiceSend') && customizeJsData?.bankDetails.invoiceSend.display == true) {
            const invoiceSendValue = generalState.configuration.get('invoiceSend') as string

            rows.push({
                key: t(currentView + 'Strings.telephoneContractData.' + 'invoiceSend'),
                value: t(
                    currentView + 'Strings.telephoneContractData.' + B2B ? invoiceSendValue + '_B2B' : invoiceSendValue,
                ),
            })
        }
        if (bankDetails.differentAccountHolder) {
            rows.push(
                {
                    key: currentView + 'Strings.bankDetails.accountHolder.salutation',
                    value: t(bankDetails.accountHolderData.salutation),
                },
                {
                    key: currentView + 'Strings.bankDetails.accountHolder.name',
                    value: bankDetails.accountHolderData.name + ' ' + bankDetails.accountHolderData.lastName,
                },
                {
                    key: currentView + 'Strings.bankDetails.accountHolder.address',
                    value: `${bankDetails.accountHolderData.street} ${bankDetails.accountHolderData.houseNumber}`,
                },
                {
                    key: currentView + 'Strings.contactData.empty',
                    value: `${bankDetails.accountHolderData.zip} ${bankDetails.accountHolderData.city}`,
                },

                {
                    key: currentView + 'Strings.contactData.empty',
                    value: `${
                        bankDetails.accountHolderData.additionalInfoAddress
                            ? bankDetails.accountHolderData.additionalInfoAddress
                            : ''
                    }`,
                },
            )
            if (salutationIsCompany(bankDetails.accountHolderData.salutation)) {
                rows.push({
                    key: 'contactDataStrings.companyName',
                    value: bankDetails.accountHolderData.company,
                })
                if (
                    customizeJsData &&
                    customizeJsData.contactDataViewConfiguration &&
                    customizeJsData.contactDataViewConfiguration.enableAlternativeLegalForm
                ) {
                    rows.push({
                        key: 'contactDataStrings.companyLegalForm',
                        value: bankDetails.accountHolderData.companyLegalForm,
                    })
                }
            }
        }

        return rows
    }

    const generateRowsForInstallationData = (generalState: GeneralState): OverviewTableRow[] => {
        const rowsToReturn: OverviewTableRow[] = [
            {
                key: currentView + 'Strings.installation.floor',
                value: generalState.installationDetails.floorInformation,
            },
            {
                key: currentView + 'Strings.installation.flatPosition',
                value: generalState.installationDetails.flatPosition,
            },
            {
                key: currentView + 'Strings.installation.flatNumber',
                value: generalState.installationDetails.flatNumber ?? '',
            },
        ]

        if (generalState.customizeJsData) {
            if (generalState.customizeJsData.installationDetailsConfiguration.junctionBox) {
                rowsToReturn.push({
                    key: currentView + 'Strings.installation.junctionBox',
                    value: generalState.customizeJsData
                        ? t('installationDetailsStrings.junctionBox.' + generalState.installationDetails.junctionBox)
                        : '',
                })
            }
            if (generalState.customizeJsData.installationDetailsConfiguration.houseConnection) {
                rowsToReturn.push({
                    key: currentView + 'Strings.installation.houseConnection',
                    value: t(
                        'installationDetailsStrings.houseConnection.' +
                            generalState.installationDetails.houseConnection,
                    ),
                })
                if (generalState.installationDetails.houseConnection === 'OptionTitle1') {
                    rowsToReturn.push({
                        key: 'installationDetailsStrings.houseConnectionInputLabel',
                        value: generalState.installationDetails.houseConnectionInput ?? '',
                    })
                }
            }
            if (generalState.customizeJsData.installationDetailsConfiguration.opticFiber) {
                rowsToReturn.push({
                    key: currentView + 'Strings.installation.opticFiber',
                    value: t('installationDetailsStrings.opticFiber.' + generalState.installationDetails.opticFiber),
                })
                if (generalState.installationDetails.opticFiber === 'OptionTitle1') {
                    rowsToReturn.push({
                        key: 'installationDetailsStrings.opticFiberInputLabel',
                        value: generalState.installationDetails.opticFiberInput ?? '',
                    })
                }
            }
        }
        return rowsToReturn
    }

    const generateRowsForDesiredDate = (generalState: GeneralState): OverviewTableRow[] => {
        const rowsToReturn: OverviewTableRow[] = []

        if (generalState.earliestDatePossible || generalState.desiredDate === null) {
            rowsToReturn.push({
                key: currentView + 'Strings.desiredDate.connection',
                value: t(currentView + 'Strings.desiredDate.earliestDatePossible'),
            })
        } else {
            rowsToReturn.push({
                key: currentView + 'Strings.desiredDate.connection',
                value: generalState.desiredDate.toLocaleDateString(),
            })
        }

        return rowsToReturn
    }

    const fillCustomerName = useCallback(
        (str: string): string => {
            let newStr = str
            if (str.includes('<Anrede>')) {
                newStr = newStr.replace('<Anrede>', contactData.personalSalutation)
            }
            if (str.includes('<Vorname>')) {
                newStr = newStr.replace('<Vorname>', contactData.personalName)
            }
            if (str.includes('<Nachname>')) {
                newStr = newStr.replace('<Nachname>', contactData.personalLastName)
            }
            return newStr
        },
        [contactData.personalSalutation, contactData.personalName, contactData.personalLastName],
    )

    const recordingText = t(currentView + 'Strings.salesPartnerInfo.stopOfRecordingText', {
        salutation: contactData.personalSalutation,
        firstName: contactData.personalName,
        lastName: contactData.personalLastName,
        today: format(new Date(), 'dd.MM.yyyy'),
    })
    return (
        <ViewWrapper
            disableBasket={true}
            disabledSubmit={disabledSubmit}
            header={currentView + 'Strings.header'}
            subHeader={currentView + 'Strings.subheader'}
            viewType={currentView}
        >
            <>
                {salesPartner && (
                    <Box pb={3}>
                        <Typography variant="h2">
                            {t(currentView + 'Strings.salesPartnerInfo.orderOverviewRecordInfoHeader')}
                        </Typography>
                        <Typography variant="body1" className={'SalesPartnerText'}>
                            {fillCustomerName(t(currentView + 'Strings.salesPartnerInfo.orderOverviewRecordInfoText'))}
                        </Typography>
                    </Box>
                )}
                <OverviewTable
                    title={getRoleAwareTranslationKey(userInfo, currentView + 'Strings.contactData.categoryTitle')}
                    rows={generateRowsForContactData(contactData)}
                    onEditClick={() => {
                        setEditType(OnEditClickTypes.ADDRESS)
                        onEditClick()
                    }}
                />
                <OverviewTable
                    title={getRoleAwareTranslationKey(userInfo, currentView + 'Strings.installation.categoryTitle')}
                    rows={generateRowsForInstallationData(generalState)}
                    onEditClick={() => {
                        setEditType(OnEditClickTypes.INSTALLATION)
                        onEditClick()
                    }}
                    marginTop={3}
                />
                {landLineContractOptionsExit && (
                    <OverviewTable
                        title={getRoleAwareTranslationKey(
                            userInfo,
                            currentView + 'Strings.telephoneContractData.categoryTitle',
                        )}
                        rows={generateRowsForTelephoneContractData(generalState, portabilityState)}
                        marginTop={3}
                        onEditClick={() => {
                            setEditType(OnEditClickTypes.TELEPHONE)
                            onEditClick()
                        }}
                    />
                )}
                <OverviewTable
                    title={getRoleAwareTranslationKey(userInfo, currentView + 'Strings.bankDetails.categoryTitle')}
                    rows={generateRowsForBankDetails(bankDetails)}
                    marginTop={3}
                    onEditClick={() => {
                        setEditType(OnEditClickTypes.BANK)
                        onEditClick()
                    }}
                />
                <OverviewTable
                    title={getRoleAwareTranslationKey(userInfo, currentView + 'Strings.desiredDate.categoryTitle')}
                    rows={generateRowsForDesiredDate(generalState)}
                    marginTop={3}
                    onEditClick={() => {
                        setEditType(OnEditClickTypes.DESIREDDATE)
                        onEditClick()
                    }}
                />
                <Box width={1} marginTop={6}>
                    <Typography variant={'h2'} className={'SectionCategory'}>
                        {t('orderOverviewStrings.costsHeader')}
                    </Typography>
                    <Box marginTop={2} flex={1} bgcolor={colors.lightGray} borderRadius={5} padding={3}>
                        <ProductTable {...basketProps.products} hideAddress={true} />
                    </Box>
                    {basketProps.costs && (basketProps.costs.monthlyCost > 0 || basketProps.costs.oneTimeCost > 0) && (
                        <Box flex={1} marginTop={3}>
                            <Grid container className={'Confirmation'}>
                                <Grid item xs>
                                    <CostsTable {...basketProps.costs} marginTop={0} />
                                </Grid>
                            </Grid>
                        </Box>
                    )}
                </Box>
                {salesPartner &&
                    generalState.customizeJsData &&
                    generalState.customizeJsData.orderOverview.showPeriodOfNoticeInfo && (
                        <Box marginBottom={3} marginTop={3}>
                            <Typography variant="h2">
                                {t(currentView + 'Strings.salesPartnerInfo.periodOfNoticeTitle')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={'SalesPartnerText'}
                                dangerouslySetInnerHTML={{
                                    __html: t(currentView + 'Strings.salesPartnerInfo.periodOfNoticeText'),
                                }}
                            />
                        </Box>
                    )}
                {salesPartner &&
                    generalState.customizeJsData &&
                    generalState.customizeJsData.orderOverview.showRightOfWithdrawalInfo && (
                        <Box marginBottom={3} marginTop={3}>
                            <Typography variant="h2">
                                {t(currentView + 'Strings.salesPartnerInfo.rightOfWithdrawalTitle')}
                            </Typography>
                            <Typography
                                variant="body1"
                                className={'SalesPartnerText'}
                                dangerouslySetInnerHTML={{
                                    __html: t(currentView + 'Strings.salesPartnerInfo.rightOfWithdrawalText'),
                                }}
                            />
                        </Box>
                    )}
                {salesPartner && (
                    <Box marginBottom={3} marginTop={3}>
                        <Typography variant="h2">
                            {t(currentView + 'Strings.salesPartnerInfo.confirmationOrderTitle')}
                        </Typography>
                        <Typography variant="body1" className={'SalesPartnerText'}>
                            {t(currentView + 'Strings.salesPartnerInfo.confirmationOrderText', {
                                product: productsText,
                                dataSheets: productsInformationSheetsText,
                                address:
                                    address.selectedStreet +
                                    ' ' +
                                    address.selectedHouseNumber +
                                    ' ' +
                                    address.selectedCity,
                            })}
                        </Typography>
                    </Box>
                )}
                <Box pt={6}>
                    <Typography variant="h6">
                        Alle Preise sind Bruttopreise inkl. der Mehrwertsteuer von derzeit 19 %
                    </Typography>
                    <Typography variant="h6" style={{ marginTop: 15 }}>
                        Bitte prüfen Sie Ihre Angaben. Eingabefehler können Sie durch Anklicken der Buttons „bearbeiten“
                        oder „zurück“ berichtigen. Durch Anklicken der Schaltfläche „Zahlungspflichtig bestellen“ geben
                        Sie Ihre Bestellung uns gegenüber ab und Ihr Auftrag wird an uns übermittelt.
                    </Typography>
                    {generalState.customizeJsData &&
                        generateCheckboxesForLegalTerms(generalState.customizeJsData.orderOverview.legalTerms)}
                    <Typography variant="h6" style={{ marginTop: 15 }}>
                        Mit Drücken des Buttons „Zahlungspflichtig bestellen“ übermitteln Sie uns Ihren Auftrag, Sie am
                        Installationsort an das öffentliche Telekommunikationsnetz anzuschließen und den Zugang zu den
                        Telekommunikationsdiensten gemäß den genannten Bedingungen zu gewährleisten. Der Vertrag kommt
                        mit der Auftragsbestätigung der Leipziger Stadtwerke zustande, die spätestens 14 Tage nach
                        Absendung des Auftrags zu erfolgen hat, soweit die technischen Voraussetzungen vorliegen. Im
                        Fall, dass eine Neuinstallation erforderlich ist, der Kunde also bislang keinen Anschluss hat,
                        werden die Leipziger Stadtwerke die Verfügbarkeit des vom Kunden gewählten Produkts am gewählten
                        Ort und den Wunschort für die Installation vor Abgabe seiner Auftragsbestätigung prüfen. Die
                        Bestätigungsfrist der Leipziger Stadtwerke verlängert sich in diesem Fall auf 30 Tage. Bitte
                        speichern oder drucken Sie den Vertragstext und die Allgemeinen Geschäftsbedingungen (AGB) für
                        Ihre eigenen Unterlagen. Die Stadtwerke Leipzig GmbH speichert Ihre Angaben bzw. den
                        Vertragstext, nachdem Sie den Button „Zahlungspflichtig bestellen“ anklicken. Die Angaben sind
                        dann nicht mehr online zugänglich. Bei Fragen helfen wir Ihnen gern weiter. Senden Sie uns
                        einfach eine E-Mail an <a href="mailto:glasfaser.stadtwerke@L.de">glasfaser.stadtwerke@L.de</a>.
                    </Typography>
                </Box>
                <Box pt={6}>
                    {generalState.customizeJsData &&
                        generalState.customizeJsData.orderOverview.newsletters &&
                        generateCheckboxes(generalState.customizeJsData.orderOverview.newsletters)}
                </Box>
                {B2B && (
                    <>
                        <Typography style={{ paddingTop: 10 }} variant="h2">
                            {t('b2bHeadline')}
                        </Typography>
                        <Typography variant="body2">{t('b2bContent')}</Typography>
                    </>
                )}
                {!B2B && (
                    <>
                        <Box pt={3}>
                            <Typography variant="h2">{t('vzf.vzfOrderTitle')}</Typography>
                        </Box>
                        <Grid container alignItems={'center'}>
                            <Grid item xs>
                                <Typography variant="h4" className={'OrderOverviewVZFRow'}>
                                    {t('vzf.yourVZFNumber')}:
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="h4" className={'OrderOverviewVZFRow'}>
                                    {vzfID}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <Box
                                    display="flex"
                                    flex={1}
                                    justifyContent={{ xs: 'stretch', sm: 'flex-end', md: 'flex-end', lg: 'flex-end' }}
                                >
                                    <Button
                                        disableFocusRipple={true}
                                        className={clsx(['FormButton', 'BackButton'])}
                                        onClick={onDownloadVZFPDF}
                                    >
                                        {t('vzf.vzfDownloadButton')}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </>
                )}
                <Box pt={2} pb={4}>
                    {generalState.customizeJsData && generateCheckboxes(generalState.customizeJsData.orderOverview.vzf)}
                </Box>
                {salesPartner && (
                    <Box marginBottom={3} marginTop={3}>
                        <Typography variant="h2">
                            {t(currentView + 'Strings.salesPartnerInfo.stopOfRecordingTitle')}
                        </Typography>
                        <Typography
                            variant="body1"
                            className={'SalesPartnerText'}
                            dangerouslySetInnerHTML={{ __html: recordingText }}
                        />
                    </Box>
                )}
                {generalState.customizeJsData && generalState.customizeJsData.globalConfiguration.enablePresentation && (
                    <Box
                        display="flex"
                        flex={1}
                        justifyContent={{ xs: 'stretch', sm: 'flex-end', md: 'flex-end', lg: 'flex-end' }}
                    >
                        <Button
                            disableFocusRipple={true}
                            className={clsx(['FormButton', 'NextButton'])}
                            onClick={onDownloadExcel}
                        >
                            Download Excel
                        </Button>
                    </Box>
                )}
                <Box width={1} marginTop={2}>
                    <Typography>{t('contactDataStrings.helperText')}</Typography>
                </Box>
            </>
        </ViewWrapper>
    )
}

export default OrderOverview
