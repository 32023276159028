import { Box, Grid, Theme, Typography, useMediaQuery } from '@material-ui/core'
import { CalendarToday } from '@material-ui/icons'
import { DatePicker } from '@material-ui/pickers'
import ConfigurationItem from 'components/ConfigurationItem'
import { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import ViewWrapper from 'components/ViewWrapper'
import { useTranslation } from 'react-i18next'
import { OptionType, ViewType } from 'store/GeneralState/GeneralState.reducer'
import TestIDs from 'utils/TestIDs'
import { getRoleAwareTranslationKey, UserInfo, useUserInfo } from 'utils/UserInfoContext'
import colors from 'utils/colors'
import { useDesiredDateReducer } from './useDesiredDateReducer'

export interface RenderDesiredDateProps {
    formatDateString: (date: Date) => string
    maxDate: () => number | Date
    minDate: () => Date
    onDateChange: (date: Date | null) => void
    setEarliestDatePossible: (payload: boolean) => void
    shouldDisableDate: (day: Date | null) => boolean
    currentView: ViewType
    desiredDate: Date | null
    earliestDatePossible: boolean
    userInfo: UserInfo | null
}

export const renderDesiredDate = (props: RenderDesiredDateProps): JSX.Element => {
    const {
        formatDateString,
        maxDate,
        minDate,
        onDateChange,
        setEarliestDatePossible,
        shouldDisableDate,
        currentView,
        desiredDate,
        earliestDatePossible,
        userInfo,
    } = props
    const { t } = useTranslation()
    const radioOptions: ConfigurationItemOption[] = [
        {
            columnValue: 12,
            label: t('earliestDatePossible'),
            mobileColumnValue: 12,
            value: '0001',
        },
        {
            columnValue: 12,
            label: t('desiredDateRequest'),
            mobileColumnValue: 12,
            value: '0002',
        },
    ]
    const isNotFullWidth = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <ConfigurationItem
                    option={radioOptions[0]}
                    type={OptionType.RADIO}
                    paddingRight={'0px'}
                    selected={earliestDatePossible}
                    onSelect={(): void => {
                        setEarliestDatePossible(true)
                    }}
                />
                <Box mt={1}>
                    <ConfigurationItem
                        option={radioOptions[1]}
                        type={OptionType.RADIO}
                        paddingRight={'0px'}
                        selected={!earliestDatePossible}
                        onSelect={(): void => {
                            setEarliestDatePossible(false)
                        }}
                    />
                </Box>
                {!earliestDatePossible && (
                    <Box display={'flex'} mr={1.5} position={'relative'}>
                        <Box zIndex={98} mt={1} display={'flex'} flex={1}>
                            <DatePicker
                                {...TestIDs.GET_DESIRED_DATE('DATE_PICKER')}
                                label={
                                    <Typography variant="body1">
                                        {t(currentView + 'DateLabel') + formatDateString(minDate())}
                                    </Typography>
                                }
                                variant={'dialog'}
                                inputVariant={'outlined'}
                                style={{ maxWidth: !isNotFullWidth ? 300 : undefined }}
                                minDate={minDate()}
                                maxDate={maxDate() === 0 ? undefined : maxDate()}
                                shouldDisableDate={shouldDisableDate}
                                helperText={
                                    <Typography
                                        variant={'subtitle1'}
                                        style={{ color: colors.darkerGray2, fontSize: '14px' }}
                                        className={'DesiredDateHelpText'}
                                    >
                                        {t(currentView + 'HelpText')}
                                    </Typography>
                                }
                                value={desiredDate}
                                onChange={onDateChange}
                                format={'dd.MM.yyyy'}
                                fullWidth={isNotFullWidth}
                                InputProps={{
                                    className: 'DesiredDate',
                                    onFocusCapture: (e): void => {
                                        e.stopPropagation()
                                        return
                                    },
                                    style: { opacity: 0.8 },
                                }}
                                // clearable={true}
                                // clearLabel={<Typography>{t('clear')}</Typography>}
                                cancelLabel={<Typography>{t('cancel')}</Typography>}
                                okLabel={<Typography>{t('ok')}</Typography>}
                            />
                        </Box>
                        <Box
                            zIndex={97}
                            mt={isNotFullWidth ? 3 : 0}
                            ml={isNotFullWidth ? -5 : 0}
                            position={isNotFullWidth ? 'relative' : 'absolute'}
                            top={isNotFullWidth ? undefined : 24}
                            left={isNotFullWidth ? undefined : 256}
                        >
                            <CalendarToday color="primary" />
                        </Box>
                    </Box>
                )}
                {t('desiredDateHint') !== 'desiredDateHint' && (
                    <Box mt={1}>
                        <Typography variant={'body1'}>{t('desiredDateHint')}</Typography>
                    </Box>
                )}
                {userInfo && userInfo.roles.includes('Vertriebspartner') && (
                    <Box pt={3}>
                        <Typography variant={'h2'}>{t('desiredDateRecordInfoHeader')}</Typography>
                        <Typography>{t('desiredDateRecordInfoText')}</Typography>
                    </Box>
                )}
            </Grid>
        </Grid>
    )
}

const DesiredDate = (): JSX.Element => {
    const [userInfo] = useUserInfo()

    const {
        currentView,
        desiredDate,
        formatDateString,
        maxDate,
        minDate,
        onDateChange,
        earliestDatePossible,
        setEarliestDatePossible,
        shouldDisableDate,
    } = useDesiredDateReducer()

    return (
        <ViewWrapper
            disabledSubmit={earliestDatePossible ? false : desiredDate !== null ? false : true}
            header={getRoleAwareTranslationKey(userInfo, 'desiredDateHeader')}
            subHeader={getRoleAwareTranslationKey(userInfo, 'desiredDateSubheader')}
            viewType={currentView}
        >
            {renderDesiredDate({
                formatDateString,
                maxDate,
                minDate,
                onDateChange,
                setEarliestDatePossible,
                shouldDisableDate,
                currentView,
                desiredDate,
                earliestDatePossible,
                userInfo,
            })}
        </ViewWrapper>
    )
}

export default DesiredDate
