import { Box, Grid, Link, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import colors from '../../utils/colors'

export interface OverviewTableRow {
    key: string
    value: string
    keyVariant?: Variant
    valueVariant?: Variant
}

export interface OverviewTableProps {
    marginTop?: number
    title: string
    rows: OverviewTableRow[]
    onEditClick: () => void
}

const OverviewTable = (props: OverviewTableProps): JSX.Element => {
    const { t } = useTranslation()
    const { marginTop, title, rows, onEditClick } = props

    return (
        <Box
            width={'calc(100% - 48px)'}
            padding={3}
            bgcolor={colors.lightGray}
            borderRadius={5}
            marginTop={marginTop ? marginTop : 0}
        >
            <Typography variant={'h2'} dangerouslySetInnerHTML={{ __html: t(title) }} />
            <Box display="flex" alignItems={'flex-start'} marginTop={2}>
                {rows.length > 0 && (
                    <Grid container spacing={2}>
                        {rows.map((row, i) => (
                            <React.Fragment key={row.key + i.toString() + row.value}>
                                {row.value.trim() !== '' && (
                                    <Grid item container spacing={2} className={'OverviewTableRow'}>
                                        <Grid item xs={12} sm={5}>
                                            <Typography
                                                variant={row.keyVariant ? row.keyVariant : 'body1'}
                                                className={'OverviewTableText'}
                                                style={{ wordBreak: 'break-all' }}
                                                dangerouslySetInnerHTML={{ __html: t(row.key) }}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={7}>
                                            <Typography
                                                className={'OverviewTableText'}
                                                variant={row.keyVariant ? row.valueVariant : 'body1'}
                                                dangerouslySetInnerHTML={{
                                                    __html: row.value !== '$' ? t(row.value) : '',
                                                }}
                                                style={{ wordBreak: 'break-all' }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                    </Grid>
                )}
                <Link
                    component={'button'}
                    onClick={onEditClick}
                    underline="hover"
                    style={{
                        marginLeft: 'auto',
                        fontSize: '15px',
                        lineHeight: '17.58px',
                        fontWeight: 'bold',
                    }}
                >
                    {t('orderOverviewStrings.editButton')}
                </Link>
            </Box>
        </Box>
    )
}

export default OverviewTable
