import { Box, TextField, Typography } from '@material-ui/core'
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab'
import ViewWrapper from 'components/ViewWrapper'
import { PostCodeDetail } from 'graphql/types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { ViewType } from 'store/GeneralState/GeneralState.reducer'
import {
    PortabilityAddress,
    PortabilityContractHolder,
    PortabilityTelephone,
    PortabilityViewFields,
} from 'store/PortabilityState/PortabilityState.reducer'
import TestIDs from 'utils/TestIDs'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import { Customize } from 'utils/customize'
import RenderAddress from './RenderAddress'
import RenderContractHolder from './RenderContractHolder'
import RenderPhoneNumbers from './RenderPhoneNumbers'
import RenderTerminatedContract from './RenderTerminatedContract'
import { usePortabilityViewReducer } from './usePortabilityViewReducer'

export interface RenderPortabilityViewProps {
    addOption: (field: PortabilityViewFields) => void
    address: PortabilityAddress
    contractHolderOptions: PortabilityContractHolder[]
    customizeJsData: Customize | undefined
    endOfContract: string
    isOptionEmpty: (option: PortabilityTelephone | PortabilityContractHolder) => boolean
    missingFields: string[]
    onChange: (field: PortabilityViewFields, label: string, index: number, value: string) => void
    phoneOptions: PortabilityTelephone[]
    removeOption: (field: PortabilityViewFields) => void
    providerOptions: string[]
    selectedProvider: string
    selectedRadios: string[]
    phoneNumbersError: (field: string, index: number) => boolean
    handleAddressSelected: (data: PostCodeDetail) => void
    handleSelectProvider: (_: React.ChangeEvent<any>, value: string) => void
    handleInputProvider: (event: React.ChangeEvent<HTMLInputElement>) => void
    B2B: boolean
}

export const renderPortabilityView = (props: RenderPortabilityViewProps): JSX.Element => {
    const { t } = useTranslation()
    const {
        addOption,
        address,
        contractHolderOptions,
        customizeJsData,
        endOfContract,
        isOptionEmpty,
        missingFields,
        onChange,
        phoneOptions,
        providerOptions,
        removeOption,
        selectedProvider,
        selectedRadios,
        phoneNumbersError,
        handleAddressSelected,
        handleSelectProvider,
        handleInputProvider,
        B2B,
    } = props
    return (
        <>
            <RenderPhoneNumbers
                addOption={addOption}
                isOptionEmpty={isOptionEmpty}
                onChange={onChange}
                options={phoneOptions}
                removeOption={removeOption}
                telephoneObjectLength={
                    customizeJsData ? customizeJsData.portabilityConfiguration.portabilityMaximumNumber : 2
                }
                phoneNumbersError={phoneNumbersError}
            />
            {customizeJsData && customizeJsData.portabilityConfiguration.previousProvider && (
                <>
                    <Typography style={{ marginTop: 20, marginBottom: 10 }} variant="h4">
                        {t('portabilityDetailsStrings.providerDropDownLabel')}
                    </Typography>
                    {providerOptions.length > 0 && (
                        <Autocomplete
                            id={'autocompleteTextField-previousProvider'}
                            options={providerOptions}
                            style={{ width: '100%' }}
                            defaultValue={selectedProvider}
                            getOptionLabel={(x) => x}
                            getOptionSelected={(x) => x === x}
                            onChange={handleSelectProvider}
                            onInputChange={handleSelectProvider}
                            renderInput={(params: AutocompleteRenderInputParams): JSX.Element => {
                                return (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        {...TestIDs.GET_PORTABILITY_DETAILS('AUTO_COMPLETE_PROVIDER')}
                                        inputProps={{ ...params.inputProps, maxLength: 150 }}
                                    />
                                )
                            }}
                            freeSolo
                            disableClearable
                        />
                    )}
                    {providerOptions.length == 0 && (
                        <TextField
                            variant="outlined"
                            className={'PreviousProviderInput'}
                            onChange={handleInputProvider}
                            {...TestIDs.GET_PORTABILITY_DETAILS('AUTO_COMPLETE_PROVIDER')}
                        />
                    )}
                </>
            )}
            {customizeJsData && customizeJsData.portabilityConfiguration.previousContractHolder && (
                <RenderContractHolder
                    addOption={addOption}
                    isOptionEmpty={isOptionEmpty}
                    selected={selectedRadios[1] === PortabilityViewFields.CONTRACT_HOLDER + 'Yes'}
                    onChange={onChange}
                    options={contractHolderOptions}
                    removeOption={removeOption}
                    telephoneObjectLength={
                        customizeJsData ? customizeJsData.portabilityConfiguration.contractHolderMaximumNumber : 2
                    }
                />
            )}
            {customizeJsData && customizeJsData.portabilityConfiguration.previousContractAddress && (
                <RenderAddress
                    address={address}
                    selected={selectedRadios[0] === PortabilityViewFields.ADDRESS + 'Yes'}
                    onChange={onChange}
                    handleAddressSelected={handleAddressSelected}
                />
            )}
            {customizeJsData && customizeJsData.portabilityConfiguration.previousContractTermination && (
                <RenderTerminatedContract
                    endOfContract={endOfContract}
                    link={
                        B2B
                            ? customizeJsData.portabilityConfiguration.B2BpreviousContractTerminationLink
                            : customizeJsData.portabilityConfiguration.B2CpreviousContractTerminationLink
                    }
                    selected={selectedRadios[2] === PortabilityViewFields.TERMINATED_CONTRACT + 'Yes'}
                    onChange={onChange}
                    missingFields={missingFields}
                />
            )}
            <Box width={1} marginTop={2}>
                <Typography>{t('contactDataStrings.helperText')}</Typography>
            </Box>
            <Box width={1} marginTop={2}>
                <Typography>{t('portabilityDetailsStrings.portabilityDisclaimer')}</Typography>
            </Box>
        </>
    )
}

const PortabilityView = (): JSX.Element => {
    const {
        addOption,
        address,
        contractHolderOptions,
        currentView,
        customizeJsData,
        disabledSubmit,
        endOfContract,
        isOptionEmpty,
        missingFields,
        onChange,
        phoneOptions,
        providerOptions,
        removeOption,
        selectedProvider,
        selectedRadios,
        phoneNumbersError,
        handleAddressSelected,
        handleSelectProvider,
        handleInputProvider,
        B2B,
    } = usePortabilityViewReducer()
    const [userInfo] = useUserInfo()

    return (
        <ViewWrapper
            disabledSubmit={disabledSubmit}
            header={currentView + 'Strings.header'}
            subHeader={getRoleAwareTranslationKey(userInfo, currentView + 'Strings.subheader')}
            viewType={currentView}
            errorCategory={ViewType.PORTABILITY_DETAILS}
        >
            {renderPortabilityView({
                addOption,
                address,
                contractHolderOptions,
                customizeJsData,
                endOfContract,
                isOptionEmpty,
                missingFields,
                onChange,
                phoneOptions,
                providerOptions,
                removeOption,
                selectedProvider,
                selectedRadios,
                phoneNumbersError,
                handleAddressSelected,
                handleSelectProvider,
                handleInputProvider,
                B2B,
            })}
        </ViewWrapper>
    )
}

export default PortabilityView
