import { Box, Collapse, Fade, Grid, Typography } from '@material-ui/core'
import { ConfigurationItemOption } from 'components/ConfigurationItem/ConfigurationItem'
import ConfigurationRadio from 'components/ConfigurationRadio'
import { AccountType, PostCodeDetail } from 'graphql/types'
import { useTranslation } from 'react-i18next'
import { BankDetailsState } from 'store/BankDetails/BankDetails.reducer'
import { getRoleAwareTranslationKey, useUserInfo } from 'utils/UserInfoContext'
import { Customize } from 'utils/customize'
import { salutationIsCompany } from 'utils/testable/functions'
import CheckboxComponent from '../../components/CheckboxComponent'
import ContactFields from '../../components/ContactFields'
import IbanInput from '../../components/IbanInput'
import ViewWrapper from '../../components/ViewWrapper'
import { Error, ViewType } from '../../store/GeneralState/GeneralState.reducer'
import TestIDs from '../../utils/TestIDs'
import { useBankDetailsReducer } from './useBankDetailsReducer'

export interface RenderBankDetailsProps {
    errors: Error[]
    inLineErrors: string[]
    setAccountHolderCity: (payload: string) => void
    setAccountHolderHouseNumber: (payload: string) => void
    setAccountHolderLastName: (payload: string) => void
    setAccountHolderName: (payload: string) => void
    setAccountHolderSalutation: (payload: string) => void
    setAccountHolderStreet: (payload: string) => void
    setAccountHolderTitle: (payload: string) => void
    setAccountHolderZip: (payload: string) => void
    setAccountHolderCompany?: (payload: string) => void
    setAccountHolderCompanyLegalForm?: (payload: string) => void
    setIban: (payload: string) => void
    setAccountType: (payload: AccountType) => void
    setConsent: (payload: boolean) => void
    setConsent2?: (payload: boolean) => void
    setDifferentAccountHolder: (payload: boolean) => void
    setAccountHolderAdditionalAddrInfo: (payload: string) => void
    setInvoiceSend: (payload: string) => void
    setDayOfTransfer: (payload: string) => void
    selectedInvoiceSendOption: string
    setBankDetailsPartial: (payload: Partial<BankDetailsState>) => void
    setEditBankDetails?: (payload: BankDetailsState) => void
    bankDetails: BankDetailsState
    dayOfTransfer: string
    customizeJsData: Customize | undefined
    showTwoConsentsIBAN: boolean | undefined
    consentLabel: string
    consentLabel2: string
    invoiceOptions: ConfigurationItemOption[]
    dayOfTransferOptions: ConfigurationItemOption[]
    consent: boolean
    setEditBankAddress?: (
        zip: string,
        city: string,
        street: string,
        houseNumber: string,
        additionalInfo: string,
    ) => void
}

export const renderBankDetails = (props: RenderBankDetailsProps): JSX.Element => {
    const {
        bankDetails,
        consentLabel,
        consentLabel2,
        customizeJsData,
        dayOfTransfer,
        errors,
        inLineErrors,
        invoiceOptions,
        selectedInvoiceSendOption,
        setAccountHolderAdditionalAddrInfo,
        setAccountHolderCity,
        setAccountHolderHouseNumber,
        setAccountHolderLastName,
        setAccountHolderName,
        setAccountHolderSalutation,
        setAccountHolderStreet,
        setAccountHolderTitle,
        setAccountHolderZip,
        setBankDetailsPartial,
        setEditBankDetails,
        setDayOfTransfer,
        setAccountHolderCompany,
        setAccountHolderCompanyLegalForm,
        setIban,
        setInvoiceSend,
        showTwoConsentsIBAN,
        setAccountType,
        setConsent,
        setConsent2,
        consent,
        setDifferentAccountHolder,
        dayOfTransferOptions,
        setEditBankAddress,
    } = props
    const { t, i18n } = useTranslation()
    const [userInfo] = useUserInfo()
    const accountTypeList: ConfigurationItemOption[] = []
    if (customizeJsData?.bankDetails.displayIBAN) {
        accountTypeList.push({
            columnValue: 12,
            label: t('bankDetailsStrings.iban'),
            mobileColumnValue: 12,
            value: '0',
        })
    }
    if (customizeJsData?.bankDetails.displayTransfer) {
        accountTypeList.push({
            columnValue: 12,
            label: t('bankDetailsStrings.transfer'),
            mobileColumnValue: 12,
            value: '1',
        })
    }

    return (
        <Grid container>
            <Grid item xs={12} {...TestIDs.GET_BANK_DETAILS('BANK_DATA')}>
                <ConfigurationRadio
                    onSelect={(value: string): void => {
                        if (bankDetails.accountType === AccountType.IBAN && value === '1') {
                            if (customizeJsData)
                                if (
                                    customizeJsData.bankDetails.displayIBAN &&
                                    customizeJsData.bankDetails.displayTransfer
                                ) {
                                    setAccountType(AccountType.TRANSFER)
                                }
                        }
                        if (bankDetails.accountType === AccountType.TRANSFER && value === '0') {
                            if (customizeJsData)
                                if (
                                    customizeJsData.bankDetails.displayIBAN &&
                                    customizeJsData.bankDetails.displayTransfer
                                ) {
                                    setAccountType(AccountType.IBAN)
                                }
                        }
                    }}
                    options={accountTypeList}
                    marginBottom={10}
                    selected={bankDetails.accountType === AccountType.IBAN ? '0' : '1'}
                    title={t('bankDetailsStrings.accountType')}
                />
                <Fade in={bankDetails.accountType === AccountType.IBAN} unmountOnExit={true} exit={false}>
                    <Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }}>
                        <IbanInput
                            label={t('bankDetailsStrings.ibanInput')}
                            onChange={setIban}
                            value={bankDetails.iban}
                            ibanError={inLineErrors.includes('iban')}
                        />
                    </Grid>
                </Fade>
                {t('bankDetailsStrings.paymentHint') !== 'bankDetailsStrings.paymentHint' && (
                    <Box width={1} marginTop={1}>
                        <Typography>{t('bankDetailsStrings.paymentHint')}</Typography>
                    </Box>
                )}
                <Box flex={1} marginTop={3} marginBottom={5}>
                    <CheckboxComponent
                        checked={consent}
                        label={t(getRoleAwareTranslationKey(userInfo, consentLabel))}
                        name={t(getRoleAwareTranslationKey(userInfo, consentLabel))}
                        onSelect={(): void => {
                            setConsent(!consent)
                        }}
                        testIdentifier={{
                            input: 'CONSENT_CHECKBOX',
                            viewType: ViewType.BANK_DETAILS,
                        }}
                    />
                </Box>
                <Box>
                    {showTwoConsentsIBAN === true && bankDetails.accountType === AccountType.IBAN && (
                        <CheckboxComponent
                            checked={bankDetails.consentCollectMoneyFromAccountChecked}
                            label={t(getRoleAwareTranslationKey(userInfo, consentLabel2))}
                            name={t(getRoleAwareTranslationKey(userInfo, consentLabel2))}
                            onSelect={(): void =>
                                setConsent2
                                    ? setConsent2(!bankDetails.consentCollectMoneyFromAccountChecked)
                                    : setBankDetailsPartial({
                                          consentCollectMoneyFromAccountChecked:
                                              !bankDetails.consentCollectMoneyFromAccountChecked,
                                      })
                            }
                            testIdentifier={{
                                input: 'CONSENT2_CHECKBOX',
                                viewType: ViewType.BANK_DETAILS,
                            }}
                        />
                    )}
                </Box>
                {customizeJsData && customizeJsData?.bankDetails.displayDayOfTransfer && (
                    <Fade in={bankDetails.accountType === AccountType.IBAN} unmountOnExit={true} exit={false}>
                        <Grid item xs={12} style={{ paddingTop: 4, paddingBottom: 4 }}>
                            <ConfigurationRadio
                                onSelect={(value): void => {
                                    setDayOfTransfer(value)
                                }}
                                options={dayOfTransferOptions}
                                marginBottom={10}
                                selected={dayOfTransfer ? dayOfTransfer : ''}
                                title={t('bankDetailsStrings.transferMonthlyTitle')}
                            />
                        </Grid>
                    </Fade>
                )}
            </Grid>
            <Grid item xs={12} {...TestIDs.GET_BANK_DETAILS('ACCOUNT_HOLDER')}>
                <ConfigurationRadio
                    onSelect={(id: string): void => {
                        if (bankDetails.differentAccountHolder && id === '0') {
                            setDifferentAccountHolder(false)
                        }
                        if (!bankDetails.differentAccountHolder && id === '1') {
                            setDifferentAccountHolder(true)
                        }
                    }}
                    options={[
                        {
                            columnValue: 12,
                            label: t(getRoleAwareTranslationKey(userInfo, 'bankDetailsStrings.meMyself')),
                            mobileColumnValue: 12,
                            value: '0',
                        },
                        {
                            columnValue: 12,
                            label: t('bankDetailsStrings.differentPerson'),
                            mobileColumnValue: 12,
                            value: '1',
                        },
                    ]}
                    marginBottom={10}
                    selected={bankDetails.differentAccountHolder ? '1' : '0'}
                    title={t('bankDetailsStrings.accountHolder')}
                />
                <Collapse
                    className={'ContactDataCollapse'}
                    in={bankDetails.differentAccountHolder}
                    unmountOnExit={true}
                    onEnter={(): void => {
                        setEditBankDetails
                            ? setEditBankDetails({
                                  ...bankDetails,
                                  accountHolderData: {
                                      companyLegalForm: '',
                                      company: '',
                                      name: '',
                                      lastName: '',
                                      salutation: '',
                                      title: '',
                                      street: '',
                                      houseNumber: '',
                                      zip: '',
                                      city: '',
                                      additionalInfoAddress: '',
                                  },
                              })
                            : setBankDetailsPartial({
                                  accountHolderData: {
                                      ...bankDetails.accountHolderData,
                                      companyLegalForm: '',
                                      company: '',
                                      name: '',
                                      lastName: '',
                                      salutation: '',
                                      title: '',
                                      street: '',
                                      houseNumber: '',
                                      zip: '',
                                      city: '',
                                      additionalInfoAddress: '',
                                  },
                              })
                    }}
                >
                    <ContactFields
                        testCategory={'ACCOUNT_HOLDER'}
                        limitedSalutationOptions={true}
                        city={bankDetails.accountHolderData.city}
                        errors={errors}
                        houseNumber={bankDetails.accountHolderData.houseNumber}
                        isAddressDisabled={false}
                        lastName={bankDetails.accountHolderData.lastName}
                        name={bankDetails.accountHolderData.name}
                        salutation={bankDetails.accountHolderData.salutation}
                        setCity={setAccountHolderCity}
                        setHouseNumber={setAccountHolderHouseNumber}
                        setLastName={setAccountHolderLastName}
                        setName={setAccountHolderName}
                        setSalutation={setAccountHolderSalutation}
                        setStreet={setAccountHolderStreet}
                        setTitle={setAccountHolderTitle}
                        setZip={setAccountHolderZip}
                        street={bankDetails.accountHolderData.street}
                        title={bankDetails.accountHolderData.title}
                        zip={bankDetails.accountHolderData.zip}
                        showAutoComplete={true}
                        showAdditionalAddressInfo={true}
                        additionalInfoAddr={bankDetails.accountHolderData.additionalInfoAddress}
                        setAdditionalInfoAddr={setAccountHolderAdditionalAddrInfo}
                        contactDataViewConfiguration={
                            customizeJsData ? customizeJsData.contactDataViewConfiguration : undefined
                        }
                        company={
                            salutationIsCompany(bankDetails.accountHolderData.salutation)
                                ? bankDetails.accountHolderData.company
                                : undefined
                        }
                        setCompany={
                            setAccountHolderCompany
                                ? setAccountHolderCompany
                                : (v) =>
                                      setBankDetailsPartial({
                                          accountHolderData: { ...bankDetails.accountHolderData, company: v },
                                      })
                        }
                        companyLegalForm={
                            salutationIsCompany(bankDetails.accountHolderData.salutation)
                                ? bankDetails.accountHolderData.companyLegalForm
                                : undefined
                        }
                        setCompanyLegalForm={
                            setAccountHolderCompanyLegalForm
                                ? setAccountHolderCompanyLegalForm
                                : (v) =>
                                      setBankDetailsPartial({
                                          accountHolderData: { ...bankDetails.accountHolderData, companyLegalForm: v },
                                      })
                        }
                        handleAddressSelected={(data: PostCodeDetail) => {
                            if (setEditBankAddress) {
                                setEditBankAddress(
                                    data.address.postcode,
                                    data.address.locality,
                                    data.address.street,
                                    data.address.buildingNumber.toString(),
                                    data.address.buildingNumberAddition,
                                )
                            } else {
                                setAccountHolderZip(data.address.postcode)
                                setAccountHolderCity(data.address.locality)
                                setAccountHolderStreet(data.address.street)
                                setAccountHolderHouseNumber(data.address.buildingNumber.toString())
                                setAccountHolderAdditionalAddrInfo(data.address.buildingNumberAddition)
                            }
                        }}
                    />
                </Collapse>
                <Box marginTop={3} />
            </Grid>

            {customizeJsData?.bankDetails.invoiceSend.display && (
                <ConfigurationRadio
                    onSelect={(value: string): void => {
                        setInvoiceSend(value)
                    }}
                    helpText={i18n.exists('invoiceSendHelpText') ? t('invoiceSendHelpText') : undefined}
                    options={invoiceOptions}
                    marginTop={25}
                    selected={selectedInvoiceSendOption}
                    title={t(getRoleAwareTranslationKey(userInfo, 'invoiceSend'))}
                />
            )}

            <Box width={1}>
                <Typography>{t('contactDataStrings.helperText')}</Typography>
            </Box>
        </Grid>
    )
}

const BankDetails = (): JSX.Element => {
    const {
        setAccountType,
        currentView,
        disabledSubmit,
        errors,
        inLineErrors,
        setAccountHolderCity,
        setAccountHolderHouseNumber,
        setAccountHolderLastName,
        setAccountHolderName,
        setAccountHolderSalutation,
        setAccountHolderStreet,
        setAccountHolderTitle,
        setAccountHolderZip,
        setIban,
        setConsent,
        consent,
        setDifferentAccountHolder,
        setAccountHolderAdditionalAddrInfo,
        setInvoiceSend,
        selectedInvoiceSendOption,
        setBankDetailsPartial,
        dayOfTransfer,
        bankDetails,
        showTwoConsentsIBAN,
        consentLabel,
        consentLabel2,
        invoiceOptions,
        dayOfTransferOptions,
        customizeJsData,
        setDayOfTransfer,
    } = useBankDetailsReducer()

    return (
        <ViewWrapper
            header={currentView + 'Header'}
            subHeader={currentView + 'Subheader'}
            viewType={currentView}
            disabledSubmit={disabledSubmit}
            errorCategory={ViewType.BANK_DETAILS}
        >
            {renderBankDetails({
                setAccountType,
                errors,
                inLineErrors,
                setAccountHolderCity,
                setAccountHolderHouseNumber,
                setAccountHolderLastName,
                setAccountHolderName,
                setAccountHolderSalutation,
                setAccountHolderStreet,
                setAccountHolderTitle,
                setAccountHolderZip,
                setIban,
                setConsent,
                setDifferentAccountHolder,
                setAccountHolderAdditionalAddrInfo,
                setInvoiceSend,
                selectedInvoiceSendOption,
                setBankDetailsPartial,
                dayOfTransfer,
                bankDetails,
                showTwoConsentsIBAN,
                consentLabel,
                consentLabel2,
                invoiceOptions,
                dayOfTransferOptions,
                customizeJsData,
                consent,
                setDayOfTransfer,
            })}
        </ViewWrapper>
    )
}

export default BankDetails
