import { Box, Collapse, Divider, Fade, useTheme } from '@material-ui/core'
import ProgressListSubItem, {
    ProgressListItemState,
    ProgressListSubItemtype,
} from 'components/ProgressListSubItem/ProgressListSubItem'
import colors from 'utils/colors'

export interface ProgressListItemProps extends ProgressListSubItemtype {
    subSteps?: ProgressListSubItemtype[]
}

const ProgressListItem = (props: ProgressListItemProps): JSX.Element => {
    const theme = useTheme()
    const { i18nKey, status, subSteps } = props
    return (
        <Box
            bgcolor={
                status === ProgressListItemState.FINISHED
                    ? colors.lightGray
                    : status === ProgressListItemState.ACTIVE
                    ? theme.palette.secondary.light
                    : colors.lightGray
            }
            mt={'0px'}
            mb={'6px'}
            borderRadius={'5px'}
        >
            <ProgressListSubItem
                progressListSubItem={{
                    i18nKey: i18nKey,
                    status: status,
                }}
                isSubItem={false}
                finishedAll={subSteps && subSteps[subSteps.length - 1].status === ProgressListItemState.FINISHED}
            />
            <Collapse in={subSteps && status !== ProgressListItemState.UNFINISHED} unmountOnExit={true}>
                <Fade in={subSteps && status !== ProgressListItemState.UNFINISHED}>
                    <div>
                        <Box pr={4}>
                            <Divider
                                variant={'middle'}
                                style={{
                                    marginBottom: 3,
                                    marginRight: 16,
                                    backgroundColor:
                                        subSteps &&
                                        subSteps[subSteps.length - 1].status === ProgressListItemState.FINISHED
                                            ? theme.palette.secondary.light
                                            : theme.palette.primary.main,
                                }}
                            />
                        </Box>
                        {subSteps &&
                            subSteps.map((item: ProgressListSubItemtype, index: number) => {
                                return (
                                    <ProgressListSubItem
                                        key={item.i18nKey + index}
                                        progressListSubItem={{
                                            i18nKey: item.i18nKey,
                                            status: item.status,
                                        }}
                                        isSubItem={true}
                                        finishedAll={
                                            subSteps &&
                                            subSteps[subSteps.length - 1].status === ProgressListItemState.FINISHED
                                        }
                                    />
                                )
                            })}
                    </div>
                </Fade>
            </Collapse>
        </Box>
    )
}

export default ProgressListItem
